const data = {
  firstPart: {
    heading: 'Annual Achievers',
    text:
      'This is a school in which your child will gain its own skill to improve himself and will do his best to make him a leader and our main motive is to make the leaders like imran khan and this the best oportunity for you and your children',
  },
  class17Data: {
    textYear: 'Class of 2017',
    data: [
      {
        id: 1,
        textHeading: 'John Doe',
        text: 'john doe is a developer but i don not know him',
        image: '/images/IMG_9465.jpg',
      },
      {
        id: 2,
        textHeading: 'Ahmed',
        text: 'john doe is a developer but i don not know him',
        image: '/images/IMG_9465.jpg',
      },
      {
        id: 3,
        textHeading: 'Waqar',
        text: 'john doe is a developer but i don not know him',
        image: '/images/IMG_9465.jpg',
      },
      {
        id: 4,
        textHeading: 'Ahsan',
        text: 'john doe is a developer but i don not know him',
        image: '/images/IMG_9465.jpg',
      },
      {
        id: 5,
        textHeading: 'Hassan',
        text: 'john doe is a developer but i don not know him',
        image: '/images/IMG_9465.jpg',
      },
    ],
  },
  class16Data: {
    textYear: 'Class of 2016',
    data: [
      {
        id: 1,
        textHeading: 'Rehan',
        text: 'john doe is a developer but i don not know him',
        image: '/images/IMG_9465.jpg',
      },
      {
        id: 2,
        textHeading: 'Taimoor',
        text: 'john doe is a developer but i don not know him',
        image: '/images/IMG_9465.jpg',
      },
      {
        id: 3,
        textHeading: 'Mushraf',
        text: 'john doe is a developer but i don not know him',
        image: '/images/IMG_9465.jpg',
      },
    ],
  },
};

export default data;
