import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class BackgroundImage extends Component {
  
  componentDidMount(){

  }
  render() {
    const { style } = this.props;
    return (
      <section >
        <div style={{width:"100%"}} className={style} />
      </section>
    );
  }
}

BackgroundImage.propTypes = {
  style: PropTypes.isRequired,
};