import React, { useEffect } from "react";
import LanguSingle from "./languSingle";

import Aos from 'aos';

import "aos/dist/aos.css";

const languList = (props) => {

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, []);
  return (
    <div data-aos="fade-up" className="langulist">
      <div className="web-banner">
        <h2>
          Software Development Technology Stack
        </h2>
        <ul>
          <li>
            <p className="techeading">Smart IT Tec Interactive’s development team only adopts modern & robust technologies to build scalable softwares tailored to your exact needs.
            </p>
          </li>
        </ul>
      </div>
      <div className="Soft-2">
        <h1>Front-end</h1>
        <div style={{marginLeft:"40px"}} className="d-flex align-items-center">
        <div >
          <button
            style={{ outline: `none` }}
            className="btn"
            value="div"
            onClick={props.handleBtn}
          ></button>
        </div>
        <div className="ret">
          <button
            style={{ outline: `none` }}
            className="btn"
            value="div2"
            onClick={props.handleBtn}
          ></button>
        </div>
        <div className="jcs">
          <button
            style={{ outline: `none` }}
            className="btn"
            value="div3"
            onClick={props.handleBtn}
          ></button>
        </div>
        </div>
        <div  style={{marginLeft:"40px",marginTop:"15px"}} className="d-flex align-items-center">
        <div className="html5">
          <button
            style={{ outline: `none` }}
            className="btn"
            value="div4"
            onClick={props.handleBtn}
          ></button>
        </div>
        <div className="css">
          <button
            style={{ outline: `none` }}
            className="btn"
            value="div5"
            onClick={props.handleBtn}
          ></button>
        </div>
        <div className="sass">
          <button
            style={{ outline: `none` }}
            className="btn"
            value="div6"
            onClick={props.handleBtn}
          ></button>
        </div>
      </div>
      </div>
      <div className="Soft-2">
        <h1>Back-end</h1>
        <div  style={{marginLeft:"40px",marginTop:"15px"}} className="d-flex align-items-center">
        <div className="php">
          <button
            style={{ outline: `none` }}
            className="btn"
            value="div7"
            onClick={props.handleBtn}
          ></button>
        </div>
        <div className="C">
          <button
            style={{ outline: `none` }}
            className="btn"
            value="div8"
            onClick={props.handleBtn}
          ></button>
        </div>
        <div className="node">
          <button
            style={{ outline: `none` }}
            className="btn"
            value="div9"
            onClick={props.handleBtn}
          ></button>
        </div>
      </div>
      </div>
      <div className="Soft-2">
        <h1>Frameworks</h1>
        <div  style={{marginLeft:"40px",marginTop:"15px"}} className="d-flex align-items-center">
        <div className="express">
          <button
            style={{ outline: `none` }}
            className="btn"
            value="div10"
            onClick={props.handleBtn}
          ></button>
        </div>
        <div className="Laravel">
          <button
            style={{ outline: `none` }}
            className="btn"
            value="div11"
            onClick={props.handleBtn}
          ></button>
        </div>
        <div className="YI">
          <button
            style={{ outline: `none` }}
            className="btn"
            value="div12"
            onClick={props.handleBtn}
          ></button>
        </div>
        </div>
        <div className="redux">
          <button
            style={{ outline: `none` }}
            className="btn"
            value="div13"
            onClick={props.handleBtn}
          ></button>
        </div>
      </div>
      <div className="Soft-2">
        <h1>CMS</h1>
        <div  style={{marginLeft:"40px",marginTop:"15px"}} className="d-flex align-items-center">

        <div className="opencard">
          <button
            style={{ outline: `none` }}
            className="btn"
            value="div16"
            onClick={props.handleBtn}
          ></button>
        </div>

        <div className="wordpress">
          <button
            style={{ outline: `none` }}
            className="btn"
            value="div17"
            onClick={props.handleBtn}
          ></button>
        </div>
      </div>
      </div>
      <div className="Soft-2">
        <h1>Database</h1>
        <div  style={{marginLeft:"40px",marginTop:"15px"}} className="d-flex align-items-center">
       <div className="MSQL">
          <button
            style={{ outline: `none` }}
            className="btn"
            value="div18"
            onClick={props.handleBtn}
          ></button>
        </div>

        <div className="PSQL">
          <button
            style={{ outline: `none` }}
            className="btn"
            value="div19"
            onClick={props.handleBtn}
          ></button>
        </div>

        <div className="MDB">
          <button
            style={{ outline: `none` }}
            className="btn"
            value="div20"
            onClick={props.handleBtn}
          ></button>
        </div>
      </div>
      </div>
      <div>
        {props.products.map((product) => {
          return <LanguSingle key={product.id} product={product} />;
        })}
      </div>
    </div>
  );
}

export default languList;