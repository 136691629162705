import React, { Component, useEffect } from "react";
import BackgroundImage from "../backgroundImage/BackgroundImage";
import { styled } from "@mui/material/styles";
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  CssBaseline,
} from "@mui/material";

import Aos from "aos";

const Webdesiging = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <BackgroundImage style={"Website-Design-img"} />
      {/* <CssBaseline /> */}
      <div data-aos="fade-up" className="web-banner">
        <div>
          <h2>
            <strong>Website Design</strong>
          </h2>
        </div>
      </div>
      {/* <------------First Grid----------------> */}
      <div data-aos="fade-up" className="testimonial-more">
        <div className="testimonial-mores">
          <h4>
            <i className="fa fa-quote-left" aria-hidden="true" />
            UNDERSTAND THE IMPORTANCE OF WEB DESIGN TO PROVIDE THE PLAIN AND
            SIMPLE INTERFACE TO THE USER THAT MAY LOOK USER-FRIENDLY AND
            CONTENT-RICH
            <i className="fa fa-quote-right" aria-hidden="true" />
          </h4>
        </div>
      </div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="designGrid1"
      >
        <Grid md={6} lg={6} xl={8} item>
          <div
            data-aos="fade-up"
            className="d-flex-justify-content-center align-items-center"
          >
            <h3>
              “If a user reads a content for 15 minutes, 66% of the user’s
              interest will be in a user-friendly design that may help the user
              to understand the context of a business.”
            </h3>
            <p>
              In this digital era, websites are being designed to promote
              businesses around the globe and the medium for 93% of business
              purchases is the internet. On the internet, millions of
              transactions are performed each day. In such a competitive
              environment, the importance of a website increases here but how
              does it looks? Here comes the website design. As soon as the
              requirements are gathered and sample business models are
              discussed, it intrigues the clients that how a website will look
              like. The website design is an important factor to understand the
              use and functions a website may perform. The outdated and complex
              web design is unable to engage the user and leaves a bad
              impression. Smart IT Tec takes the website design seriously and
              seeks feedback from the client that may help in making the website
              more content-rich and functional. The web design based on the
              primary principles seeks more attention from the user.
            </p>
          </div>
        </Grid>
        <Grid item md={6} lg={5} xl={4}>
          <div
            data-aos="fade-up"
            className="d-flex-justify-content-center align-items-center"
          >
            <img
              className="animated"
              style={{ width: "90%" }}
              src="../images/web-design/WebDesign4.png"
              alt=""
            />
          </div>
        </Grid>
      </Grid>
      {/* <----------------------------> */}
      {/* <------------Second Grid----------------> */}
     <div data-aos="fade-up" className="testimonial-more">
        <div className="testimonial-mores">
          <h4>
            <i className="fa fa-quote-left" aria-hidden="true" />
            A CONSISTENT WEBSITE DESIGN HELP YOU BUILD BRAND RECOGNITION AND
            ENGAGE THE AUDIENCE FOR A LONG TIME & THE STYLE GUIDE IS IMPORTANT
            TO BRING DESIGN CONSISTENCY.
            <i className="fa fa-quote-right" aria-hidden="true" />
          </h4>
        </div>
      </div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="designGrid1 designGrid2"
      >
        <Grid item md={6} lg={5} xl={4}>
          <div
            data-aos="fade-up"
            className="d-flex-justify-content-center align-items-center"
          >
            <img
              className="animated"
              style={{ width: "90%" }}
              src="../images/web-design/webDesign2.png"
              alt=""
            />
          </div>
        </Grid>
        <Grid md={6} lg={6} xl={8} item>
          <div
            data-aos="fade-up"
            className="d-flex-justify-content-center align-items-center"
          >
            <h3>
              “An appealing and consistent web design is important to mark the
              strong impression over the user that may turn the visit to a lead
              through your updated marketing strategy.”
            </h3>
            <p>
              Businesses are depending upon the showcased services that may be
              achieved by the consistent website designs. Such website designs
              help in search engine optimization that how may this content look
              over the website? SEO mainly depends on the way of publishing
              content over the websites that in return enhances the website
              index in searches. Consistent website design builds up trust among
              your audience and conveys them the latest information about your
              business and lets them stay with you in the business. An outdated,
              old, and low-quality website design will help your competitors to
              rank you out of the market, So apply the basic updated principles
              of design to your existing or newly created website and ring the
              bell of success. Let’s partner-up yourself with an expert and
              advanced digital marketing agency that may help you achieve all
              such factors, that is none other than Smart IT Tec.
            </p>
          </div>
        </Grid>
      </Grid>
      {/* <----------------------------> */}
      {/* <------------Third Grid----------------> */}
      <div data-aos="fade-up" className="testimonial-more">
        <div className="testimonial-mores">
          <h4>
            <i className="fa fa-quote-left" aria-hidden="true" />
            THE WEBSITE DESIGN SETS A STRONG IMPRESSION TO THE CUSTOMER CARE
            THAT HELP IN RETAINING THE NUMBER OF VISITS.
            <i className="fa fa-quote-right" aria-hidden="true" />
          </h4>
        </div>
      </div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="designGrid1"
      >
        <Grid md={6} lg={6} xl={8} item>
          <div
            data-aos="fade-up"
            className="d-flex-justify-content-center align-items-center"
          >
            <h3>
              “Update your website design according to the modern principles of
              web design that may help in engaging the potential clients keeping
              you in the market competition.”
            </h3>
            <p>
              Smart IT Tec expert’s designs are user-friendly and rich in
              content. We know the importance of a website for your business and
              the user experience for your visitor. Smart IT Tec has a wide
              range of themes design, banner designs, logo designs, mobile
              interface designs, etc. The use of a consistent color scheme makes
              your website pleasant and appealing. Such planned and strategic
              design helps in solid navigation inside the website. Easy
              navigation helps them collect the entire information about your
              business. The use of call-to-action buttons properly and
              accurately will open up the door to the marketing funnel. Simple
              and self-explanatory information with broad headings and numerous
              sub-topics if designed and placed carefully will explain the whole
              business model. Above all such strategies help you make your
              website responsive and robust.
            </p>
          </div>
        </Grid>
        <Grid item md={6} lg={5} xl={4}>
          <div
            data-aos="fade-up"
            className="d-flex-justify-content-center align-items-center"
          >
            <img
              className="animated"
              style={{ width: "90%" }}
              src="../images/web-design/WebDesign3.png"
              alt=""
            />
          </div>
        </Grid>
      </Grid>

      {/* <----------------------------> */}
      {/* <-------------Four Grid---------------> */}
      <div data-aos="fade-up" className="testimonial-more">
        <div className="testimonial-mores">
          <h4>
            <i className="fa fa-quote-left" aria-hidden="true" />
            THE SALIENT FEATURES OF WEBSITE DESIGN CAN ONLY BE ACHIEVED BY
            CONSULTING THE EXPERT DIGITAL MARKETING AGENCY & SMART IT TEC WOULD
            BE YOUR BEST CHOICE.
            <i className="fa fa-quote-right" aria-hidden="true" />
          </h4>
        </div>
      </div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="designGrid1 designGrid2"
      >
        <Grid item md={6} lg={5} xl={4}>
          <div
            data-aos="fade-up"
            className="d-flex-justify-content-center align-items-center"
          >
            <img
              className="animated"
              style={{ width: "90%" }}
              src="../images/web-design/WebDesign5.png"
              alt=""
            />
          </div>
        </Grid>
        <Grid md={6} lg={6} xl={8} item>
          <div
            data-aos="fade-up"
            className="d-flex-justify-content-center align-items-center"
          >
            <h3>
              “Get a website design done by an expert digital marketing agency
              that may show expertise in responsiveness, robustness, and
              impressive user experience.”
            </h3>
            <p>
              Smart IT Tec practices updated and industry-approved principles in
              Website Designing. The appealing website design is the return on
              investment that will engage more customers to enhance the leads
              and a high rise in the earned revenue. Smart IT Tec experts keep
              the page loading speed and search engine optimization techniques
              in mind while designing the website designs, which makes us the
              market leaders. Smart IT Tec takes the website design as an
              important factor and can enhance the existing design of your
              website according to the updated design principles. It will give
              your website a new fresh look that may add up audience figures to
              it. Start the journey of best user experience with Smart IT Tec at
              cost-effective and market competitive rates. Join hands with Smart
              IT Tec and experience all the updated industry services.
            </p>
          </div>
        </Grid>
      </Grid>
      {/* <----------------------------> */}

      {/* <div data-aos="fade-up">
        <Typography data-aos="fade-up-right" variant="h4">
          “If a user reads a content for 15 minutes, 66% of the user’s interest
          will be in a user-friendly design that may help the user to understand
          the context of a business.”
        </Typography>
      </div> */}
      {/* <Grid container spacing={2} className="about" style={{ backgroundColor: '#f0f0f0', padding: '20px', minHeight: '80vh' }}  data-aos="fade-up"> */}
      {/* Content for larger screens (medium and up) */}
      {/* <Grid item md={6} xs={12} style={{ display: 'flex', alignItems: 'center' }} data-aos="fade-up">
          <Box
            className="custom-box"
            style={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',

              padding: '20px',

            }}
          >
            <Typography variant="h1" style={{ fontFamily: 'Roboto, sans-serif', marginBottom: '16px',color:"#fff" }}>
              About Us
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Roboto, sans-serif', display: 'inline', marginBottom: '16px',color:"#fff" }}>
              Smart IT Tec is a digital media marketing agency that brings creativity and uniqueness in one place. If you
              are looking to generate leads, to hold the market share, and scaling up the business with an awesome partner,
              your search stops here. We, Smart IT Tec have the best artists who will shape your dream and will bring the
              best art out of it. From the years of experience, we’ve learned the advantages of different mediums. These
              medium works well when paired strategically. The full-service strategy delivered to our clients helps to
              enhance market recognition, lead gens, and above all, the revenue. Smart IT Tec as a team is determined to
              provide the best cutting edge technical solutions to modern world problems of the industry ranging from a small
              scale company to corporate level industry. Partner with us and land on google’s best ranking.
            </Typography>
            <Button variant="contained" color="primary">
              Read More
            </Button>
          </Box>
        </Grid> */}

      {/* Image for larger screens (medium and up) */}
      {/* <Grid item md={6} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }} >
          <Box className="hero-img" data-aos-delay="200">
            <img
              src="/images/about-img.png"
              alt="Your Image"
              className="img-fluid animated"
              style={{ width: '100%', maxWidth: '600px', height: 'auto' }}
            />
          </Box>
        </Grid>
        <Box className="box">
            <Box className="box">.</Box>
            <Box className="box">.</Box>
            <Box className="box">.</Box>
            <Box className="box">.</Box>
            <Box className="box">.</Box>
            <Box className="box">.</Box>
            <Box className="box">.</Box>
            <Box className="box">.</Box>
            <Box className="box">.</Box>
            <Box className="box">.</Box>
          </Box>
      </Grid> */}
    </div>
  );
};

export default Webdesiging;
