import React, { useEffect } from 'react';
import BackgroundImage from "../backgroundImage/BackgroundImage";

import Aos from 'aos';

import "aos/dist/aos.css";


const Ecommerce = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, []);
  return (
    <div className="ecommerce">
      <BackgroundImage style={"Ecommerce-img"} />
      <section className="service-page bg-icegrey about-ecommerce">
        <div className="container">
          <div data-aos="fade-up" className="row">
            <div className="ecommerce-banner">
              <div>
                <h2>E-commerce website
              <strong> We build what you dream!</strong>
                </h2>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 visible-xs visible-sm">
              <img
                src="/images/E-commerce/ecommerce-design.png"
                className="img-responsive center-block"
                alt="Ecommerce Design"
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <h1>
                <span className="h3">
                  Smart IT Tec will feature your products online by developing an
                  E-commerce Website.
              </span>
              </h1>
              <p>
                A unique business model and custom design with product quality and
                quantity make your business successful. But, these all factors
                can’t fulfill business success without online integration.
                Understanding the importance of online retail business, Smart IT
                Tec experts will bring you the custom E-Commerce Store. Showcasing
                product categories and sub-categories with different variants,
                specifications, and prices lay the foundation of E-commerce. An
                E-commerce store provides room comfort to your customer that
                allows them to buy products online. Smart IT Tec has expertise in
                making online stores according to the desired requirements of the
                client. Our experts will suggest the technology that will show
                better behavior over the online platform. Our product
                recommendation and collaborative filtering algorithm help you
                gather many audiences around the globe. It not only brings traffic
                but also raises the company’s revenue and ranking. We assure our
                clients of a robust, responsive, and smooth-running online
                market/store. Your reasonable E-Commerce development partner’s
                choice should be Smart IT Tec.
            </p>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 hidden-xs hidden-sm">
              <img
                src="/images/E-commerce/ecommerce-design.png"
                className="img-responsive center-block"
                alt="Ecommerce Design"
              />
            </div>
          </div>
          <div className="row">
            <div data-aos="zoom-out-left" className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <img
                src="/images/E-commerce/ecommerce-webpage-design.png"
                className="img-responsive center-block"
                alt="Ecommerce Designed Webpage"
              />
            </div>
            <div data-aos="zoom-out-right" className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <h2>
                <span className="h3">
                  “Enjoy the best experience of professionally designed &
                  developed E-commerce Websites by Smart IT Tec.
              </span>
              </h2>
              <p>
                Smart IT Tec has proven expertise in building an E-commerce
                website that fulfills the client requirement. An E-commerce
                website passes through different phases that make its development
                complicated but Smart IT Tec experts can make this platform
                responsive and error-free. We can develop your E-commerce platform
                from scratch to the proper finished product or revamping the
                existing product. Your brought idea will be refined and planned by
                the in house team of Smart IT Tec. We can provide you with the
                best-developed product in WordPress and Magento. Site Design and
                Development phase need hawkeye to keep issues/errors away from the
                system. Smart IT Tec doesn’t leave the customer alone here rather
                provides marketing and support from the same business. Marketing
                an online store extends the company’s reach to its targeted
                customers and supports help to run the business’s online platform
                flawlessly. Smart IT Tec provides these features to its customers
                at very reasonable and affordable prices. We deal with every
                individual the same because they all matter to us.
            </p>
            </div>
          </div>
        </div>
      </section>
      <section className="our-features clearfix">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="text-center center-block">
                <h2>
                  <span className="h3-title">
                    How do we provide the best
                  <span>E-commerce web development solutions?</span>
                  </span>
                </h2>
                <p>
                  Our strategically planned design and development of custom
                  features distinguish us from the rest of the others.
              </p>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="row">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <div data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="4000" className="box-feature">
                <div className="text-center center-block">
                  <img
                    src="/images/E-commerce/hosted_solution.png"
                    className="img-responsive center-block"
                    alt="Hosted Solution"
                  />

                  <h4>Hosted Solution</h4>
                  <p>
                    Our best-provided web-hosted solutions keep you away from any
                    issue and provide you best reliable hosting services around
                    the globe.
                </p>
                </div>
              </div>
            </div>
            <div data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="4000" className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <div className="box-feature">
                <div className="text-center center-block">
                  <img
                    src="/images/E-commerce/responsive_themes.png"
                    className="img-responsive center-block"
                    alt="Fully Responsive Themes"
                  />

                  <h4>Fully Responsive Themes</h4>
                  <p>
                    Enjoy the best experience of fully responsive themes chosen or
                    created by our experts that will run flawlessly over the web.
                </p>
                </div>
              </div>
            </div>
            <div data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="4000" className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <div className="box-feature">
                <div className="text-center center-block">
                  <img
                    src="/images/E-commerce/inventory_management.png"
                    className="img-responsive center-block"
                    alt="Inventory Management"
                  />

                  <h4>Inventory Management</h4>
                  <p>
                    The E-commerce solutions provided by our experts keep a proper
                    record of either in-stock or non-capitalized assets around
                    your system that makes tracking easier.
                </p>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="row">
            <div data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="4000" className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <div className="box-feature">
                <div className="text-center center-block">
                  <img
                    src="/images/E-commerce/banners_coupons.png"
                    className="img-responsive center-block"
                    alt="Ecommerce Designing Coupons"
                  />

                  <h4>Banners, Coupons, Promos</h4>
                  <p>
                    Our experts facilitate you with options to display
                    custom-designed banners, discounted coupons, and promotional
                    displays.
                </p>
                </div>
              </div>
            </div>
            <div data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="4000" className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <div className="box-feature">
                <div className="text-center center-block">
                  <img
                    src="/images/E-commerce/bulk_product_import.png"
                    className="img-responsive center-block"
                    alt="Product Bulk Import"
                  />

                  <h4>Product Bulk Import</h4>
                  <p>
                    To import the data effortlessly and tirelessly, Smart IT Tec
                    provides you the option to move the bulk of data to your
                    E-commerce Website.
                </p>
                </div>
              </div>
            </div>
            <div data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="4000" className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <div className="box-feature">
                <div className="text-center center-block">
                  <img
                    src="/images/E-commerce/social_media_conectivity.png"
                    className="img-responsive center-block"
                    alt="Social Media Connectivity"
                  />
                  <h4>Social Media Connectivity</h4>
                  <p>
                    To shout out your running campaigns, social media plays a
                    vital role in it. We will connect your professional social
                    media account to your website that extends your reach
                    globally.
                </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cta-ecommerce">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div data-aos="zoom-out" className="demo-container">
                <img
                  src="/images/E-commerce/ecommerce_design.jpg"
                  alt="Ecommerce Design"
                  title="Ecommerce Design"
                  className="img-responsive"
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <div className="text-left">
                <div className="clearfix"></div>
                <h2>
                  <span className="h3-title">
                    Smart IT Tec is bringing you exceptional
                  <span>E-commerce Web Solutions.</span>
                  </span>
                </h2>
                <p>
                  Presenting you the professional and dynamic E-commerce web
                  solutions for your online store. We serve our clients all around
                  the globe having an in-house team in Pakistan. We help our
                  customers in uplifting their existing or newly started business
                  by providing design and development support at affordable, and
                  reasonable prices. At Smart IT Tec we fulfill the dreams of our
                  customers and bring the required product to them.
              </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="how-it-works">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="text-center center-block">
                <span className="h3-title">
                  How We
                <span>Create</span>
                </span>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
              <div className="text-center center-block">
                <img data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="4000"
                  src="/images/E-commerce/design_analyzing.png"
                  className="img-responsive center-block"
                  alt="Design Analyzing"
                />
                <h4 data-aos="zoom-out">Analyze</h4>
                <p data-aos="zoom-out">
                  The first thing we do is analyze your brand and the products you
                  are offering as well as your requirements in the eCommerce
                  website. Based upon that we conduct our research of competitors
                  and Google requirements.
              </p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
              <div className="text-center center-block">
                <img data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="4000"
                  src="/images/E-commerce/ecommerce_design_plan.png"
                  className="img-responsive center-block"
                  alt="Ecommerce Design Plan"
                />
                <h4 data-aos="zoom-out">Plan</h4>
                <p data-aos="zoom-out">
                  Once we have all the required information and designs in front
                  of us, our expert e-commerce web developers plan the procedure
                  of developing your e commerce store effectively with the best
                design, features, and functioning.
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
              <div className="text-center center-block">
                <img data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="4000"
                  src="/images/E-commerce/ecommerce_store_creation.png"
                  className="img-responsive center-block"
                  alt="Ecommerce Store Creation"
                />
                <h4 data-aos="zoom-out">Create</h4>
                <p data-aos="zoom-out">
                  Once proper research and planning is done, our web experts get
                  to work to provide you with the best web solutions flawlessly.
                  We implement the plan to achieve the results for you and build
                the best e-commerce store for your brand.
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
              <div className="text-center center-block">
                <img data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="4000"
                  src="/images/E-commerce/ecommerce_design_deploy.png"
                  className="img-responsive center-block"
                  alt="Design Deliver"
                />
                <h4 data-aos="zoom-out">Deliver</h4>
                <p data-aos="zoom-out">
                  After developing the website, we test it for perfect working and
                  SEO friendliness. If the e-commerce website passes the test then
                  we immediately deliver the work to you. Otherwise, we make sure
                to properly fix the issues before delivering.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section data-aos="fade-up" className='divider'>
        <div className='container wow fadeInUp animated'>
          <div className='row'>
            <div className='col-md-8 col-md-offset-2'>
              <div className='divider-para'>
                <p>
                  <i className='fa fa-quote-left' /> An impressive design
                    alone is not enough; you need to provide a great experience
                    to the user by offering the user-friendly design.{' '}
                  <i className='fa fa-quote-right' />
                </p>
              </div>
              <div data-aos="zoom-out-down"
                data-aos-duration="3000" className='divider-img'>
                <img src='/images/Logo-design/logo_03_1.png' alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
};

export default Ecommerce;
