const images = [
  {
    path: '/images/Hero.jpg',
    caption: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
    key: 1,
  },
  {
    path: '/images/IMG_0224.JPG',
    caption: 'sed do eiusmod tempor incididunt ut labore',
    key: 2,
  },
  {
    path: '/images/IMG_0348.JPG',
    caption:
      'quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    key: 3,
  },
  {
    path: '/images/IMG_9463.JPG',
    caption: 'deserunt mollit anim id est laborum.',
    key: 4,
  },
];

export default images;
