import React, { Component } from 'react';

import BackgroundImage from '../backgroundImage/BackgroundImage';

export default class Sem extends Component {
	componentDidMount() {}
	render() {
		return (
		  <div className="SemApp">
		   <BackgroundImage style={'Sem-img'} />
			<div>
				<div className="coming">
					<img src="images/sem/ComingSoon.jpg" alt=""/>
				</div>
			</div>
		  </div>
		);
	}
}
