import React, { useEffect } from "react";
import { Container, Grid, Box, Typography, Button, CssBaseline } from '@mui/material';

import Aos from 'aos';

import "aos/dist/aos.css";
const Gallery = () => {

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, []);

  return (
    <div className="home">
      <Grid container spacing={2} className="about" style={{ backgroundColor: '#f0f0f0', padding: '20px', minHeight: '80vh' }} data-aos="fade-up">
        {/* Content for larger screens (medium and up) */}
        <Grid item md={6} xs={12} style={{ display: 'flex', alignItems: 'center' }} data-aos="fade-up">
          <Box
            className="custom-box"
            style={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',

              padding: '20px',

            }}
          >
            <Typography variant="h1" style={{ fontFamily: 'Roboto, sans-serif', marginBottom: '16px', color: "#fff" }}>
              About Us
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Roboto, sans-serif', display: 'inline', marginBottom: '16px', color: "#fff", fontSize: "16px" }}>
              Smart IT Tec is a Software Company that brings creativity and uniqueness in one place. If you are looking to generate leads, hold the market share, and scaling up the business with an incredible partner, your search stops here. We, Smart IT Tec have the best artists who will shape your dream and will bring the best art out of it. From the years of experience, we’ve learned the advantages of different mediums. This medium works well when paired strategically. The full-service strategy delivered to our clients helps to enhance market recognition, lead gens, and revenue. Smart IT Tech as a team is determined to provide the best cutting-edge technical solutions to the industry’s modern world problems ranging from a small-scale company to a corporate level industry. Partner with us and land on Google’s best ranking.
            </Typography>

          </Box>
        </Grid>

        {/* Image for larger screens (medium and up) */}
        <Grid item md={6} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }} >
          <Box className="hero-img" data-aos-delay="200">
            <img
              src="/images/about-img.png"
              alt="Your Image"
              className="img-fluid animated"
              style={{ width: '100%', maxWidth: '600px', height: 'auto' }}
            />
          </Box>
        </Grid>
        <Box className="box">
          <Box className="box">.</Box>
          <Box className="box">.</Box>
          <Box className="box">.</Box>
          <Box className="box">.</Box>
          <Box className="box">.</Box>
          <Box className="box">.</Box>
          <Box className="box">.</Box>
          <Box className="box">.</Box>
          <Box className="box">.</Box>
          <Box className="box">.</Box>
        </Box>
      </Grid>
      <section data-aos="fade-up" className="service-page pad-bottom-0">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div data-aos="fade-up" className="service-title text-center">
                <h1>
                  Smart IT Tec Brings you secure & high performing
                  architecture, keeping the user
                </h1>
                <h1 data-aos="fade-up" className="website-item">
                  experience and response time in mind
                </h1>
                <h2 data-aos="fade-up" >...</h2>
              </div>
            </div>
            <div data-aos="fade-up" className="col-md-12">
              <div className="service-left wow fadeInUp animated animated">
                <p>
                  In the ocean of the IT Industry, Smart IT Tec is the name of
                  the trust. We helped many businesses by facilitating them
                  with strategic plans to shape their desired requirements
                  with a secure & robust environment introduced in the
                  industry with the modern skillsets. Bringing you the result
                  oriented website portfolio is the priority.
                </p>
                <h2>
                  Optimized content for a website is like a soul for a body,
                  We help your website residing on the first page!
                </h2>
                <p>
                  Being first in the competition is the desire of every
                  contestant, but the winner always comes with good
                  preparation. Landing your website on the first page is the
                  aim of Smart IT Tec. Well designed online portfolio grabs
                  the attention of the audience, Quick response enhances the
                  user experience. Bringing it all in one place makes you a
                  good website, Now your business is ready to hit the market.
                  Smart IT Tec develops websites that help you stand out in
                  the crowd.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div data-aos="fade-up" className="testimonial-more">
        <div className="testimonial-mores">
          <h4>
            <i className="fa fa-quote-left" aria-hidden="true" />
            Website Development is not a cup of tea, it takes to plan, design,
            modern skills, experience and above all commitment.
            <i className="fa fa-quote-right" aria-hidden="true" />
          </h4>
        </div>
      </div>
    </div>
  );
}
export default Gallery;
