// ########## Import Dependencies Here ##########
import React, {  useEffect } from "react";
// import PropTypes from 'prop-types';

// ########## Import Screens Here ##########

// ########## Import Components Here ##########
// import ReadMoreButton from './ReadMoreButton';

import Aos from 'aos';

import "aos/dist/aos.css";

const JuniorSchool = () => {

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, []);

    return (
      <section className="services-page pad-bottom-0">
        <div className="container">
          <div className="row">
            <div  data-aos="fade-up" className="col-md-12">
              <div className="col-md-7">
                <div className="services-left wow fadeInUp animated animated">
                  <h3>
                    {" "}
                    DO YOU WANT TO OFFER BEST IMPRESSION OF YOUR BUSINESS
                    THROUGH WEB DESIGNING SERVICES? ENJOY THE ELITE ART DESIGN
                    EXPERIENCE WITH SMART IT TEC.
                  </h3>
                  <p>
                    Smart IT Tec is committed to its words, quality work is
                    provided to the customer based on the promise made. Our
                    basic aim is to provide the customer with satisfied
                    deliverables & we have been successful in it. Retaining the
                    customer in the IT industry is not easy, but the presence of
                    our audience out there is speaking itself. Smart IT Tec will
                    partner with the different businesses of every niche ranging
                    from the personal profile to the company portfolio, food
                    ordering to an e-commerce website, and online bookings to
                    CMS. You just contact us and the next moment we’ll be ready
                    to shape your dream. We also provide services in Logo
                    designing, Graphic designing, theme, color, and font
                    selection or your website according to your desire. Your
                    requirements and our skillset is the best blend introducing
                    the future business holding the market share.
                  </p>
                </div>
              </div>
              <div className="col-md-5">
                <div className="carousel">
                  <div className="item">
                    <img
                      src="/images/Home/Untitled-2.png"
                      alt="First slide"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div  data-aos="fade-up" className="row">
            <div  className="col-md-6">
              <div className="services-right wow fadeInRight animated animated">
                <img
                  className="img-responsive"
                  src="/images/Home/Untitled-3.png"
                  alt="web-services"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="services-left wow fadeInUp animated animated">
                <h2>
                  BEST RETURN ON INVESTMENTS HELP THE BUSINESS TO LEAD THE
                  MARKET, HIRE THE SMART IT TEC & BE THE MARKET COMPETITIVE
                  COMPANY.
                </h2>
                <p>
                  IT industry also follows Darwinian Evolutionary theory
                  “Survival of the fittest”. In this digital world, your
                  business only survives if you laid your strong foundation on
                  the internet. Being in the access of every user is the main
                  goal of every business, we cater to your website according to
                  the screen being used. 60% of the businesses are searched from
                  the cell phone, So we leave no stone unturned for you and
                  brings you the best interfaces available on the internet.
                  Smart IT Tec will also help you in rebuilding or reshaping the
                  existing website and will make the required changes according
                  to the need of the day.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default JuniorSchool;
