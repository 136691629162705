export const languApi=[
    {
        id:1,
        cat:'div',
        title:'AngularJS',
        description:'This framework is perfect for the creation of video streaming apps, user-generated content portals, and social apps. It’s a time-saving tool that lets developers write less code and achieve greater functionality based on the MVC architecture.'
    },
    {
        id:2,
        cat:'div2',
        title:'React',
        description:'Advanced JS library for building UI with the ability to reuse system components, which significantly boosts productivity and facilitates further maintenance. React help build high-load apps with stable code and continuous app performance.'
    },
    {
        id:3,
        cat:'div3',
        title:'JavaScript',
        description:'A programming language that makes it possible to turn static pages into interactive ones. Using JavaScript, it is possible to create dynamically updated content and animate images. JavaScript is very popular since it is an easy-to-use language, it is supported by almost all browsers.'
    },
    {
        id:4,
        cat:'div4',
        title:'HTML5',
        description:'In general, HTML stands for Hypertext Markup Language that is used together with CSS to markup and tailor the look of pages. The fifth is the latest version of HTML containing a lot of enhancements that allow dealing with more engineering aspects.'
    },

    {
        id:5,
        cat:'div5',
        title:'CSS',
        description:'Cascading Style Sheets define how HTML elements will be displayed on a page. CSS is used to recreate user interfaces composed by designers. Put simply, the button you see on a website is an HTML element but it was colored and centered by means of CSS.'
    },
    {
        id:6,
        cat:'div6',
        title:'SASS',
        description:'SASS is a CSS pre-processor that lets us make writing CSS much more powerful by using variables, loops, and other functionalities. Sass helps us write clean, easy and less CSS in a programming construct. It is stable, powerful, and elegant and compatible with all versions of CSS.'
    },
    {
        id:7,
        cat:'div7',
        title:'PHP',
        description:'The abbreviation of PHP stands for "Personal Home Page". Originally, it was designed as a simple scripting language and then evolved into something more over time. Nowadays, this programming language is widely used for server-side programming.'
    },
    {
        id:8,
        cat:'div8',
        title:'C#',
        description:'An object-oriented language that allows developers to create various secure and reliable apps that run on .Net Framework. It is considered to be one of the most powerful and demanded languages. A unique language that is used to build native Windows apps, mobile apps, REST APIs and other software.'
    },
    {
        id:9,
        cat:'div9',
        title:'NodeJS',
        description:'NodeJS is an event-driven technology for creating real-time web applications. Node.js is perfect for creating data-heavy solutions as it is capable of handling traffic spikes. NodeJS is successfully applied for various website application development services.'
    },
    {
        id:10,
        cat:'div10',
        title:'Express.js',
        description:'This is a lightweight and open-source JavaScript-based framework for Node.js server environment. It is designed for building the server-side of web applications as well as APIs. Express.js is a part of the MEAN stack together with MongoDB and AngularJS.'
    },
    {
        id:11,
        cat:'div11',
        title:'Laravel',
        description:'Laravel is a PHP framework for developing scalable, feature-rich and secure websites and apps. The projects are delivered in a short period of time. Laravel makes integration with third-party tools easier and helps to secure web apps efficiently.'
    },
    {
        id:12,
        cat:'div12',
        title:'YII2',
        description:'High-performance PHP framework that implements MVC pattern. Designed for fast development of large web apps. Due to a large number of possible tools, YII2 is used for building apps with high traffic flow. It is efficient and provides developers with a detailed documentation.'
    },
    {
        id:13,
        cat:'div13',
        title:'ASP.net MVC',
        description:'The power of this framework is utilized by developers from all over the globe for building scalable and maintainable web applications. The Model-View-Controller architectural pattern at the heart of the framework enables an efficient development process.'
    },
    {
        id:16,
        cat:'div16',
        title:'OpenCart',
        description:'A free-to-use e-commerce platform that is designed for creating online shopping. This engine can be installed on any web server with MySQL and PHP support. It has a high functionality level, user-friendly interface and it is easy to install. OpernCart allows you to create profitable e-commerce solutions.'
    },
    {
        id:17,
        cat:'div17',
        title:'Wordpress',
        description:'Wordpress is a cost-effective and SEO friendly CMS for website creation. WordPress is an extremely user-friendly platform, its convenient administration panel makes it easy to implement changes to your website. Additional functionality is gained by plugins implementation.'
    },
    {
        id:18,
        cat:'div18',
        title:'MySQL',
        description:'MySQL is a stable, reliable and powerful database management system. It offers on-demand scalability and allows complete customization to eCommerce businesses. MySQL is designed to meet demanding requirements and ensure performance.'
    },
    {
        id:20,
        cat:'div19',
        title:'PostgreSQL',
        description:'The PostgreSQL relational database is distributed for free. Whats more, its source code is available to all those wishing to utilize it. The database is not controlled by any organization or private entity and each engineer can contribute to its development.'
    },
    {
        id:21,
        cat:'div20',
        title:'MongoDB',
        description:'NodeJS is an event-driven technology for creating real-time web applications. Node.js is perfect for creating data-heavy solutions as it is capable of handling traffic spikes. NodeJS is successfully applied for various website application development services.'
    },
    
    
]