import React from "react";
import BackgroundImage from "../backgroundImage/BackgroundImage";



export default class Contact extends React.Component {
  render() {
    return (
      <div id="contact">
        <BackgroundImage style={"contact-img"} />
        <section className="contact-info">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 text-center">
                <address
                  className="wow fadeInUp animated"
                  style={{ visibility: `visible` }}
                  /*style="visibility: visible; animation-name: fadeInUp;"*/
                >
                  <h3>MAIN OFFICE</h3>
                  <h5>You are always welcome to stop by and say hello !</h5>
                  <p>Lahore Punjab , Pakistan</p>
                  <p>+923407101829</p>
                  <p>info@smartittec.com</p>
                </address>
                <form
                  id="form__"
                  className="wow fadeInUp animated"
                  method="post"
                  style={{ visibility: `visible` }}
                  /*style="visibility: visible; animation-name: fadeInUp;"*/
                >
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      placeholder="Your name"
                      className="spacing"
                      required=""
                    />
                    <input
                      type="text"
                      name="email"
                      placeholder="E-mail"
                      required=""
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="phone"
                      placeholder="Phone Number"
                      className="spacing"
                      required=""
                    />
                    <input
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      required=""
                    />
                  </div>

                  <div className="form-group">
                    <textarea
                      name="detail"
                      placeholder="Your message"
                      required=""
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <div>
                      <div
                        className="grecaptcha-badge"
                        data-style="none"
                        style={{
                          width: `256px`,
                          height: `60px`,
                          position: `fixed`,
                          visibility: `hidden`,
                        }}
                      >
                        <div className="grecaptcha-logo"></div>
                        <div className="grecaptcha-error"></div>
                        <textarea
                          id="g-recaptcha-response-1"
                          name="g-recaptcha-response"
                          className="g-recaptcha-response"
                          style={{
                            width: `250px`,
                            height: `40px`,
                            margin: `10px 25px`,
                            border: `1px solid rgb(193, 193, 193)`,
                            padding: `0px`,
                            resize: `none`,
                            display: `none`,
                          }}
                        ></textarea>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn-custom g-recaptcha"
                      data-sitekey="6LfJzP8UAAAAAObL-LReRzzfU5tKAZ0GaPiItX3i"
                      data-callback="onSubmit__"
                      data-action="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
