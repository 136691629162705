import React, { useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";

library.add(faFacebookF);

import Aos from "aos";

import "aos/dist/aos.css";

const Footer = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div id="footer">
      <footer>
        <div data-aos="fade-up" className="container wow fadeInUp">
          <div className="row">
            <div className="col-sm-12 col-md-4">
              <h4>ABOUT SMART IT TEC</h4>
              <p>
              Smart IT Tec is a Software Company that brings creativity and uniqueness in one place. If you are looking to generate leads, hold the market share, and scaling up the business with an incredible partner, your search stops here. We, Smart IT Tec have the best artists who will shape your dream and will bring the best art out of it. From the years of experience, we’ve learned the advantages of different mediums. This medium works well when paired strategically.
              </p>
            </div>
            <div className="col-sm-12 col-md-2">
              <h4>USEFUL LINKS</h4>
              <ul className="footer-menu fa-ul">
                <li>
                  <a href="/">
                    <i className="fa-li fa fa-check" /> Home
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa-li fa fa-check" /> Services
                  </a>
                </li>
                <li>
                  <a href="/contact">
                    <i className="fa-li fa fa-check" /> Contact Us
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm-12 col-md-3">
              <h4>OUR SERVICES</h4>
              <ul className="footer-menu fa-ul">
                <li>
                  <a href="/web-development-services">
                    <i className="fa-li fa fa-check" /> Web Development
                  </a>
                </li>
                <li>
                  <a href="/website-design">
                    <i className="fa-li fa fa-check" /> UI/UX
                  </a>
                </li>
                <li>
                  <a href="/mobile-app-development">
                    <i className="fa-li fa fa-check" /> Mobile App Development
                  </a>
                </li>
                <li>
                  <a href="/electrical-project-development">
                    <i className="fa-li fa fa-check" />
                    Electrical Project Development
                  </a>
                </li>
                <li>
                  <a href="/ecommerce-website-development">
                    <i className="fa-li fa fa-check" /> E-commerce Web
                    Development
                  </a>
                </li>
                <li>
                  <a href="/search-engine-optimization">
                    <i className="fa-li fa fa-check" />
                    Search Engine Optimization
                  </a>
                </li>
                <li>
                  <a href="/logo">
                    <i className="fa-li fa fa-check" /> Logo Design
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h4>CONTACT DETAILS</h4>
              <p>Lahore Punjab , Pakistan</p>
              <p>
                <strong>Address :</strong> Wilson Ave, Timmins, ON P4N
                2S6,ontario, CA
              </p>
             
              <p>
                <strong>Email :</strong> info@smartittec.com
              </p>
              <p>
                <strong>Phone :</strong> +92 340-7101829
              </p>
              <p>
                <strong>Phone :</strong> +1 (506) 897-0682
              </p>
              <ul className="list-inline list-social">
                <li id="fb">
                  <a
                    href="https://web.facebook.com/smart.it.te"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-f" aria-hidden="true" />
                  </a>{" "}
                </li>
                <li id="inst">
                  <a
                    href="https://www.instagram.com/smart_it_tec/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </li>
                <li id="tw">
                  <a
                    href="https://twitter.com/Ramay4A"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i className="fab fa-twitter" aria-hidden="true" />
                  </a>
                </li>
                <li id="in">
                  <a
                    href="https://www.linkedin.com/company/smartittec/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin-in" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="float-sm">
          <div className="fl-fl float-fb">
            <i className="fab fa-facebook-f" />
            <a
              href="https://web.facebook.com/smart.it.te"
              rel="noopener noreferrer"
              target="_blank"
            >
              {" "}
              Like us!
            </a>
          </div>
          <div className="fl-fl float-tw">
            <i className="fab fa-twitter" />
            <a
              href="https://twitter.com/Ramay4A"
              rel="noopener noreferrer"
              target="_blank"
            >
              Follow us!
            </a>
          </div>
        </div>
      </footer>
      <div className="sub-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h6>Copyright © 2023 Smart IT Tec All Rights Reserved.</h6>
            </div>
          </div>
          <div data-aos="fade-up" className="pull-right-1">
            <a href="#" className="back-to-top">
              <i className="fa fa-chevron-up" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;