import React, { useEffect } from "react";

import BackgroundImage from "../backgroundImage/BackgroundImage";

import Aos from 'aos';

import "aos/dist/aos.css";


const Seo = () => {

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, []);

  return (
    <div className="Seo">
      <BackgroundImage style={'Seo-img'} />
      <div data-aos="fade-up" className="seo-rank">
        <div className="top">
          <h2>
            Top
              <strong>SEO RANKING</strong>
          </h2>
        </div>
        <div className="seo-ranking">
          <div data-aos="fade-right" className="seo-img">
            <img src="/images/seo/target.png" alt="" />
          </div>
          <div data-aos="fade-up-left" className="seo-item">
            <h3>HIT THE TARGET</h3>
            <p>
              Hit the top position in searches by using Smart IT Tec
              expertise.
              </p>
          </div>
          <div data-aos="fade-left" className="seo-img-2">
            <img src="/images/seo/analytics.png" alt="" />
          </div>
          <div data-aos="fade-up-left" className="seo-item">
            <h3 className="width">VIGILANT RANKING</h3>
            <p>Our experts keep SEO factors in focus to rank your website.</p>
          </div>
          <div data-aos="fade-left" className="seo-img-3">
            <img src="/images/seo/img-3.png" alt="" />
          </div>
          <div data-aos="fade-up-left" className="seo-item">
            <h3 className="width">WORLDWIDE RANKING</h3>
            <p>
              We help to keep your company in top positions globally as we are
              the best.
              </p>
          </div>
          <div data-aos="fade-right" className="seo-img-4">
            <img src="/images/seo/img-4.png" alt="" />
          </div>
          <div data-aos="fade-up-left" className="seo-item">
            <h3>Rank #1</h3>
            <p>We are #1 in SEO, which brings more traffic to our sitec.</p>
          </div>
          <div data-aos="fade-left" className="seo-img-5">
            <img src="/images/seo/img-5.png" alt="" />
          </div>
          <div data-aos="fade-up-left" className="seo-item">
            <h3 className="width-1">ENGINE OPTIMIZATION</h3>
            <p>
              In-house Smart IT Tec SEO Experts strategically place you on
              top.
              </p>
          </div>
          <div data-aos="fade-left" className="seo-img-6">
            <img src="/images/seo/img-6.png" alt="" />
          </div>
          <div data-aos="fade-up-right" className="seo-item">
            <h3 className="width">COMPETITOR RESEARCH</h3>
            <p>
              Our expert SWOT Analysts can figure out the market competition
              and will plan accordingly.
              </p>
          </div>
        </div>
      </div>
      <div data-aos="zoom-in-up" data-aos-easing="linear"
        data-aos-duration="1500" className="seo-on-page">
        <div className="seo-items-stretch">
          <div className="seo-line-1"></div>
          <h2>EFFECTIVE ON-PAGE SEO</h2>
          <div className="seo-line-2"></div>
        </div>
        <div className="seo-imgs">
          <img src="/images/seo/Seo1.png" alt="" />
        </div>
        <div className="seo-onpage-p">
          <p className="seo-onpage-p-1">
            In a puzzle, we gather structure and content accordingly to meet
            the watcher’s requirement. Web page structure and content is also
            a puzzle and Smart IT Tec has a quick solution for it..
            </p>
          <img data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000" src="/images/seo/Optimization-icon.png" alt="" />
          <p className="seo-onpage-p-2">
            Smart IT Tec SEO experts always assure to rank your public web
            content that could be easily searched by the search engine. Then
            why remain behind when you got such a wonderful SEO partner, Smart
            IT Tec.
            </p>
          <img data-aos="flip-right"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
            className="seo-images"
            src="/images/seo/On-page-optimization-icon.png"
            alt=""
          />
          <p className="seo-onpage-p-3">
            Search Engine Optimization is a game full of keywords. Our experts
            figure out the keywords of the relevant industry or niche and add
            them strategically to your web page. Then bring hands together to
            your SEO partner, Smart IT Tec.
            </p>
        </div>
      </div>
      <div data-aos="zoom-in-down" data-aos-easing="linear"
        data-aos-duration="1500" className="seo-of-page">
        <div className="seo-of-stretch">
          <div className="seo-of-1"></div>
          <h2>EFFECTIVE OFF-PAGE SEO </h2>
          <div className="seo-of-2"></div>
        </div>
        <div className="seo-ofpage-p">
          <p className="seo-ofpage-p-1">
            Focusing on the quality of the links, but not the quantity of the
            links Smart IT Tec is providing premium services to its customers.
            We bring you the new trends in the changing world of Web Searches.
            </p>
          <img data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000" src="/images/seo/Optimization-icon.png" alt="" />
          <p className="seo-ofpage-p-2">
            We provide you authentic backlinks that help to rank on the first
            page by using modern and Industry-Recommended practices. Our
            reliable resources help us to put you on your required search
            engine.
            </p>
          <img data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
            className="seo-images"
            src="/images/seo/On-page-optimization-icon.png"
            alt=""
          />
          <p className="seo-ofpage-p-3">
            Planned off-site SEO help in establishing backlink profiles with
            highly visited websites that make your brand authentic. Quality
            links provided by Smart IT Tec experts will raise your business to
            new heights.
            </p>
        </div>
        <div className="seo-of-imgs">
          <img src="/images/seo/Seo2.png" alt="" />
        </div>
      </div>
      <div data-aos="zoom-in-up" data-aos-easing="linear"
        data-aos-duration="1500" className="seo-detailed-page">
        <div className="seo-detailed-stretch">
          <div className="seo-detailed-1"></div>
          <h2>DETAILED TRANSPARENT REPORTING</h2>
          <div className="seo-detailed-2"></div>
        </div>
        <div className="seo-detailed-imgs">
          <img src="/images/seo/Seo2.png" alt="" />
        </div>
        <div className="seo-detailed-p">
          <p className="seo-detailed-p-1">
            When our customers pay for the premium SEO services, we take it as
            a duty to report them that how things are being done? So, we drive
            our clients on the same journey. Our reports provide you
            analytics.
            </p>
          <img data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000" src="/images/seo/Optimization-icon.png" alt="" />
          <p className="seo-detailed-p-2">
            Before and after analysis of your website are kept in front of you
            in the form of screenshots and videos that display's that where we
            have been reached. Our reporting proves that we are the experts in
            our work.
            </p>
          <img data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
            className="seo-images"
            src="/images/seo/On-page-optimization-icon.png"
            alt=""
          />
          <p className="seo-detailed-p-3">
            We don’t put you in hit and trial strategy but we take the
            analytics and links straight to you. These backlinks authenticate
            your existence and keep you on the first pages.
            </p>
        </div>
      </div>
      <div data-aos="zoom-in-up" className="seo-important">
        <div className="seo-important-why">
          <h2>What Search Engine Optimization (SEO) Services do for you?</h2>
          <p>
            In Search Engine Optimization, content plays a vital role in
            changing the game and Smart IT Tec knows it very well. We bring
            the audience globally to your online platform by using the right
            keywords that average users search for. Use Smart IT Tec premium
            services and generate high revenue from your respective audience.
            </p>
        </div>
      </div>
      <div data-aos="zoom-in-up" data-aos-easing="linear"
        data-aos-duration="1500" className="seo-search">
        <div className="seo-search-engine">
          <h2>Search Engine Optimization</h2>
          <p>
            Hire Smart IT Tec expert Search Engine Optimization team to
            optimize your content and secure top rankings in the search
            engine. At Smart IT Tec we focus on top ranking, optimized
            content, boosted traffic, and measured outcomes.
            </p>
        </div>
        <div>
          <div className="seo-search-optimization">
            <h2>WHY SMART IT TEC ?</h2>
            <p>
              Smart IT Tec has provided its customers with premium Search
              Engine Optimization (SEO) services over the year and help them
              in generating revenue from higher traffic rates. Our experts
              have always provided the latest modern techniques to optimize
              the website in the search engine that’s the reason why we stand
              out from the competitive service providers. We have been
              retaining customer’s satisfaction by providing them genuine and
              useful keywords that still bring them on top. Relevancy is a
              prior factor in Search Engine Optimization (SEO) that brings the
              exact searches according to the mind of the searcher. We care
              about your top position by analyzing the exact keywords and
              resourceful content for your website. Keeping client’s
              recommendations and requirements, Smart IT Tec brings different
              reasonable and affordable market competitive packages that help
              in deciding the optimization goals. If you want to freeze the
              first position for you then Smart It Tec should be your choice.
              </p>
          </div>
          <div
            className="seo-search-img"
          >
            <img
              src="/images/seo/seo-services.png"
              className="img-fluid animated"
              alt=""
            />
          </div>
        </div>
        <div className="seo-company">
          <div className="seo-company-required">
            <h2 data-aos="fade-up-right" >Why Top SEO Company required?</h2>
            <div data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000" className="seo-company-img">
              <img src="/images/seo/seo-services5.png" alt="" />
            </div>
          </div>
          <div data-aos="fade-up-right" className="seo-company-p">
            <p>
              The relevant and genuine Search Engine Optimization (SEO) comes
              with the use of modern and latest technology trends, analytical
              tools, and a handful of research. Whereas, different companies
              in the market provide fake and unauthentic links that can’t last
              long and the client’s business gets a big hit. It not only gives
              space to the client’s competitors but also the service provider
              loses authenticity. Smart IT Tec understands these factors and
              tries not to play a stunt to change the game. We at Smart IT Tec
              give priority to authenticity, relevancy, and long-lasting
              optimization. This rare combination of market behavior has
              earned us name and fame in the digital global market. Our
              satisfied clients have opened up different big opportunities for
              us that speak of our authenticity. The client’s experience and
              positive feedback about Search Engine Optimization (SEO) have
              given us confidence & made the company top-rated. Smart IT Tec
              has a global community on the platform and we are working with
              them on long term projects. Smart IT Tec practices the industry
              standards and it made us Top SEO Service provider globally. Hire
              Smart IT Tec for Search Engine Optimization (SEO) Services, and
              enjoy an unforgettable experience with us.
              </p>
          </div>
        </div>
      </div>
      <div className="seo-can-do">
        <div className="what-can-we-do">
          <h2>HOW SMART IT TEC WILL OPTIMIZE YOUR CONTENT ?</h2>
          <p>
            Smart IT Tec experts will help you secure top rankings and
            featured
            </p>
          <p className="what-can-we-do-p">
            Position by practicing SEO friendly optimization.
            </p>
          <div className="seo-before-after">
            <img src="/images/seo/seo-before-after.gif" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Seo;