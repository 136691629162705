const dataForEvents=[
	{
		id:1,
		thumbnail:"image-source",
		style:'left-image-div',
		description: 'description 1',
		amountOfPhotos: '100',
		year:'2017'
	},
	{
		id:2,
		thumbnail:"image-source",
		style:'left-image-div',
		description: 'description 2',
		amountOfPhotos: '100',
		year:'2017'
	},
	{
		id:3,
		thumbnail:"image-source",
		style:'left-image-div',
		description: 'description 3',
		amountOfPhotos: '100',
		year:'2017'
	},
	{
		id:4,
		thumbnail:"image-source",
		style:'left-image-div',
		description: 'description 4',
		amountOfPhotos: '100',
		year:'2017'
	},

]
export default dataForEvents;