import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BackgroundImage from '../backgroundImage/BackgroundImage';
// import Calender from '../calender/Calender';
import Gallery from '../gallery/Gallery';
import Promo from '../promo/Promo';
import * as actions from '../../actions';

class Home extends Component {

  render() {
    // const { style } = this.props;

    return (
      <div className="Home">
        <BackgroundImage style={'hero-image'} />  
        <Gallery />
        <Promo />
        
      </div>
    );
  }
}



// function mapStateToProps(state) {
//   return {
//     test: state.test
//   };
// };

export default Home;
