import React, { useEffect } from 'react';

// import Particles from 'react-particles-js';

import BackgroundImage from '../backgroundImage/BackgroundImage';
import Aos from 'aos';

import "aos/dist/aos.css";


const Logo = () => {

  useEffect(() => {
    Aos.init({duration: 2000})
  }, []);

    return (
      <div  className='Logo'>
        <BackgroundImage style={'logo-img'} />
        <div data-aos="fade-up" className='col-md-6'>
          <h3>PROFESSIONAL LOGO DESIGN FOR YOUR BRAND IDENTITY</h3>
        </div>
        <div data-aos="fade-up" className='col-md-6 mt-4-mt md-0'>
          <div className='hero-img'  data-aos-delay='200'>
            <img
              src='/images/Logo-design/logo1.png'
              className='img-fluid animated'
              alt=''
            />
          </div>
          <h2>
            Get your Logo Design, Online Logo Design, Cheap Logo Design & Custom
            Logo for your brand identity as well improve brand visibility
          </h2>
          <p>
            Every business whether it is small or on large scale needs to
            consider a brand identity. Your brand is one of your most quickly
            recognizable assets. Logos are vital because they act as the face of
            your brand. A professional Logo Design communicate people what you
            do, what you trust in, demonstrate your vision & it provides them a
            good reason to connect to your company. An excellent Logo Design
            should inspire trust, be memorable & crave an everlasting
            impression.
          </p>
          <p>
            At Smart It Tec we will help you identify how to best showcase your
            brand identity & impress your audience because we are experts in all
            fields of branding & visual identity with more than 14 years’
            experience in designing best logos which make an impression &
            inspire trust and loyalty, therefore your audience/ customers come
            to you every time.
          </p>
        </div>
        <section data-aos="fade-up" className="service-page pad-bottom-0">
          <div  className="container">
            <div className="row">
              <div className="col-sm-12">
                <div  data-aos="fade-up" className="service-title text-center">
                  <h1>
                  WE HAVE CREATED THE CUSTOM LOGO DESIGNS 
                  </h1>
                  <h1 className="website-item">
                  FOR THE DIFFERENT
                  BUSINESSES
                  </h1>
                  <h2>...</h2>
                </div>
              </div>
              <div  data-aos="fade-up" className="col-md-12">
                <div className="service-left wow fadeInUp animated animated">
                  <p>
                  We know that how passionate our customers are for their business
                  and therefore understanding their feelings; our team members work
                  hard to provide them with the amazing logos that would be helpful
                  to boost their business. Every brand has its story, and at the Smart
                  It Tec, we try our best to use the same story to create your
                  logo. The colours and the design we use are exactly according to
                  the needs of our customers due to which it is easy for us to
                  create a logo that relates your business very easily.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div  data-aos="fade-down" className='testimonial-more'>
          <div className='testimonial-mores'>
            <h4>
              <i className='fa fa-quote-left' aria-hidden='true' />
              Before putting the pencil to the paper, we connect the different
              points together such as the brand story, the brand name and the
              logic behind the brand to create the perfect logo.
              <i className='fa fa-quote-right' aria-hidden='true' />
            </h4>
          </div>
        </div>
        <div  data-aos="fade-up" className='create'>
          <div className='creater'>
            {/* <Particles
              params={{
                particles: {
                  number: {
                    value: 50,
                  },
                  size: {
                    value: 3,
                  },
                  move: {
                    direction: 'bottom-right',
                  },
                },
                interactivity: {
                  events: {
                    onhover: {
                      enable: true,
                      mode: 'repulse',
                    },
                  },
                },
              }}
            /> */}
            <h2>How to Create Logo Design Impressively</h2>
            <p>
              Versatile & fresh image is very important, for impressive image of
              your company please contact Smart it Tec Logo Design expert team.
              Choosing the exact designer is key your success. Professional Logo
              Design i.e. graphic art skills are very essential & it takes
              professional training to learn how they should be implement for
              business.Smart it Tec has successfully worked with over 150
              companies on the development of brand identity. We may guide you
              through the process of Logo Design success & then tailor you how
              to roll it out to your remaining business.
            </p>
          </div>
          <div className='hero-img'  data-aos-delay='200'>
            <img
              src='/images/Logo-design/logo3.png'
              className='img-fluid animated'
              alt=''
            />
          </div>
          <div className='box'>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
          </div>
        </div>
        <div  data-aos="fade-up" className='d-flex'>
          <div className='align-items-stretch'>
            <div className='b-line'></div>
            <h2>Logo & Identity Design</h2>
            <p>
              The logo is the face of your Brand. We have the most talented and
              experienced logo designers, who approach every logo design project
              with an inspired dedication with a complete thought process. Being
              a focus based quality branding agency in Pakistan, we have been
              designing logos and brand identities for many reputed companies
              and that’s something we are good at.
            </p>
          </div>
          <div className='testimonial-img'>
            <img src='/images/Logo-design/logo2.png' alt='' />
          </div>
        </div>
        <div  data-aos="fade-up" className='wpb_wapper'>
          <div className='max-title'>
            <h2>
              How Smart It Tec May Help in
              <strong>Logo Design</strong>
            </h2>
          </div>
          <div className='wpb_text_column wpb_content_element'>
            <p>
              Smart It Tec is highly experts in Logo Design, Online Logo Design,
              Cheap Logo Design & Custom Logo because it is very essential for
              companies to have an impressive & consistent visual identity.
              Following is the process of best Logo Design for your company:
            </p>
          </div>
          <div className='vc_row wpb_row'>
            <ul className='plus'>
              <li>
                Research for your Logo Design i.e.what makes your brand
                prominent as well customer perceptions
              </li>
              <li>Competitive Analysis for your Logo Design</li>
              <li>Multiple Logo Design for your feedback</li>
            </ul>
          </div>
          <div className='vc_column'>
            <img src='/images/Logo-design/build-logo.gif' alt='' />
          </div>
        </div>
        <div  data-aos="fade-up" className='wpb_wapper'>
          <div className='max-title'>
            <h2>
              <strong>PROMINENT BENEFITS </strong> 
               OF CUSTOM LOGO
            </h2>
          </div>
          <div className='wpb_text_column wpb_content_element'>
            <p>
              Smart It Tec highly experts Custom Logo Designers take the time to
              understand your business, then create a Custom Logo which
              perfectly serves your requirements. You will be happy to display
              it everywhere as your business deserves a Custom Logo. Following
              are the prominent benefits of Custom Logo:
            </p>
          </div>
          <div className='vc_row wpb_row'>
            <ul className='plus'>
              <li>Custom Logo make you more memorable</li>
              <li>Custom Logo portrays a professional image</li>
              <li>Custom Logo provides consistency</li>
              <li>Custom Logo brings maximum ROI</li>
              <li>Custom Logo unique to your business</li>
            </ul>
          </div>
        </div>
        <section  data-aos="fade-up" className='divider'>
          <div className='container wow fadeInUp animated'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2'>
                <div className='divider-para'>
                  <p>
                    <i className='fa fa-quote-left' /> An impressive design
                    alone is not enough; you need to provide a great experience
                    to the user by offering the user-friendly design.{' '}
                    <i className='fa fa-quote-right' />
                  </p>
                </div>
                <div data-aos="zoom-out-down"
                  data-aos-duration="3000" className='divider-img'>
                  <img src='/images/Logo-design/logo_03_1.png' alt='' />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
export default Logo;
