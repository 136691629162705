/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from "react";

import BackgroundImage from "../backgroundImage/BackgroundImage";

import Aos from 'aos';

import "aos/dist/aos.css";


const Webdevelopment = () => {

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, []);

  return (
    <div className="Webdevelopment">
      <BackgroundImage style={"webdevelopment-img"} />

      <div data-aos="fade-up" className="web-banner">
        <div>
          <h2>
            <strong>WEB DEVELOPMENT COMPANY</strong>
          </h2>
        </div>
        <div className="web-about">
          <p>
            <strong>Smart It Tec</strong> A brand in the domain of web
              development agencies. We bring you a wide range of website
              designs, from 1-page custom web design to a whole new market
              competitive website design. Bringing your business into the market
              competition, Your dire need should be an interactive website. To
              be the market influencer, the website design should be the
              showstopper. Our experts help you to visualize the market success
              from the beginning and land you into a wonderful world of Smart IT
              Tec. Our different success stories speak of our provided quality
              service. Client satisfaction is the primary outcome of our
              business model that builds long-term business relations.
            </p>
        </div>
        <div data-aos="fade-up" className="hero-left">
          <h3 >
            USER EXPERIENCE ENGAGES THE CUSTOMER AND CONVERTS TRAFFIC PRODUCER
            INTO CUSTOMER.
            </h3>

          <div className="react">
            <p>
              Enhanced and performance-based user experience grabs customer
              attention. Easy to use, Interactive, and High performing website
              guarantees customer addition. User experience is the fine blend
              of interfaces, layouts, visuals, texts, branding, and customer
              interaction. A single click builds customer interaction as the
              narrative of user experience. Even our experts make the single
              click more interactive and responsive that assures success.
              Keeping your elite standards in mind,
                <strong>Smart It Tec </strong>
                mold your ideas into an alluring and sophisticated web design.
                Website is the actual representation of the business that better
                narrates the flow and mechanism followed. Easy navigation on the
                website helps users taking a tour that adds more effectiveness
                to the user experience. Once your website impresses visitors,
                Smart IT Tec craft will change visitors into customers.
              </p>
            <div
              className="web-img"

              data-aos-delay="200"
            >
              <img
                src="/images/web-development/web-development1.png"
                className="img-fluid animated"
                alt=""
              />
            </div>
          </div>
        </div>
        <div data-aos="fade-up" className="testimonial-more">
          <div className="testimonial-mores">
            <h4>
              <i className="fa fa-quote-left" aria-hidden="true" />
                WEBSITE IS YOUR 24/7 PROMOTION, ADDING MODERN TECHNIQUES AND NEW
                FEATURES ATTRACTS 21st CENTURY CUSTOMERS.
                <i className="fa fa-quote-right" aria-hidden="true" />
            </h4>
          </div>
        </div>
        <div data-aos="fade-down" className="hero-left">
          <div
            className="web-images"
            data-aos-delay="200"
          >
            <img
              src="/images/web-development/web-development7.png"
              className="img-fluid animated"
              alt=""
            />
          </div>
          <div className="react-js">
            <h3>
              THE “WEB ARTISTS” AT SMART IT TEC AIMS TO DEVELOP USER-FRIENDLY
              AND SEARCH ENGINE OPTIMIZED WEBSITES.
              </h3>
            <div className="under-line"></div>
            <p>
              Using relevant keywords to optimize your website content always
              enhances your customer reach by increasing your search engine
              ranking. Our optimized content for your website always places
              your website on top of the list. Your top appearance brings you
              good revenue by converting visits to sales.
              </p>
          </div>
          <div className="web-react">
            <h3>
              <strong> SMART IT TEC </strong> PROVIDES YOU A WIDESPREAD OF
                TECHNOLOGIES RANGING FROM SIMPLE TECHNOLOGIES TO COMPLEX NATURE.
              </h3>
            <div className="under-line"></div>
            <p>
              We create the best art out of your choice and selection
              providing you a wide spectrum of different technologies.
              Different technologies available in the market presents your
              website information and responses in a better way. All these
              technologies blend to build a secure and responsive website
              according to the user perspective.
              </p>
          </div>
        </div>
        <div className="Third-Party">
          <div>
            <h2>
              <strong>THIRD PARTY INTEGRATION</strong>
            </h2>
          </div>
          <div className="Third-Party-P">
            <h3>Get a custom app quicker with APIs</h3>
            <p>
              The Startupers are coming with a short budget and time, they are
              looking to start a business quickly to start earning revenue.
              Most startups aim to turn revenue into owner equity and make the
              platform better later. Here come the APIs, that shortens the
              development time, reduces cost, efficient performance, and is
              ready to use. The use of APIs is common in B2B and B2C
              businesses and covers the major part of businesses like
              Communication, Storage, Marketing, Social Media, Payment
              Gateways, and Accounting. Developing a customized web platform
              for a business integrated with APIs is not an easy task but
              Smart IT Tec developers can do it perfectly. Bring your idea and
              our experts will suggest to you the resource that needs to
              implement which will work perfectly for you.
              </p>
            <div
              className="Third-Party-img"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <img
                src="/images/web-development/Third-Party-Integration.png"
                className="img-fluid animated"
                alt=""
              />
            </div>
            <div className="box">
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" className="ahmad">
          <div className="col-md-10 col-md-offset-1">
            <div className="wow fadeInUp text-center h-center ">
              <h3>
                This how we <strong>Create</strong>
              </h3>
              <div className="process-title">
                <small>A dream website for you!</small>
              </div>
              <div data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500" className="our-process-wrap">
                <div className="our-process-item">
                  <i className="fas fa-clipboard-list" />
                  <h4>Analyze</h4>
                  <p>
                    After gathering information and a business model from the
                    client, our experts prepare the feasibility report that
                    best defines the product and provide the requirement
                    document to the client. Once the client approves the
                    document, we dedicate resources for the proposed business.
                    </p>
                </div>
                <div className="our-process-item">
                  <i className="fas fa-magic" />
                  <h4>Plan</h4>
                  <p>
                    Better planning in the development helps in running a
                    smooth flow of all the modules of the digital
                    representation of the business. Our experts plan the
                    website to be interactive and user-friendly in every
                    context. Our team defines the module to be covered at a
                    specific time.
                    </p>
                </div>
                <div className="our-process-item">
                  <i className="fas fa-filter" />
                  <h4>Create</h4>
                  <p>
                    Bringing a physical business to digital representation is
                    difficult work but, planning makes it easier. Our experts
                    use the defined themes, designs, technologies, business
                    models, platform information, etc. to develop the website.
                    On-site development and testing create it quickly and
                    accurately.
                    </p>
                </div>
                <div className="our-process-item">
                  <i className="fas fa-desktop" />
                  <h4>Deliver</h4>
                  <p>
                    We ensure to deliver the order to our customers on the
                    time that is according to their needs having the quality
                    that would be eliminating all sort of risks and the doubts
                    you have about your website.The developed product is ready
                    for delivery after onsite testing meeting the client’s
                    given deadline.
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section data-aos="fade-up" className="container">
          <div className="row-wrapper-x"></div>
        </section>
        <section data-aos="fade-up" className="blox w-animate  w-start_animation">
          <div className="container">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <ul className="devpg_new">
                      <li>Cost-effective and efficient Web Application </li>
                      <li> &amp; Web Development Services</li>
                    </ul>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element  vc_custom_1598870172637">
                  <div className="wpb_wrapper">
                    <p>
                      Our over the year of experience helped businesses in
                      digitizing their business models, increased customer
                      figures, and high revenue generation. Customized
                      Websites &amp; Apps developed by our experts are
                      cost-effective and efficiently responsive. We help
                      businesses by quoting good prices compared to the
                      digital market globally. Prices at the Smart IT Tec
                      could be negotiable but the quality of the product will
                      always remain top of the line. To give a better
                      understanding of the developed product, our team of
                      experts trains the end-user for future use. Our on-time
                      delivery of the desired product has always played a
                      vital role in retaining the customer’s number. We at
                      Smart IT Tec are looking into the digital future and
                      recommend our visitors to take an opportunity to work
                      with us.
                      </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section data-aos="fade-up" className="divider">
          <div className="container wow fadeInUp animated">
            <div className="row">
              <div className="col-md-8 col-md-offset-2">
                <div className="divider-para">
                  <p>
                    <i className="fa fa-quote-left" /> User-friendly website
                      design retains customers' tour time that gives the whole
                      information to the client easily.
                      <i className="fa fa-quote-right" />
                  </p>
                </div>
                <div data-aos="zoom-out-down"
                  data-aos-duration="3000" className="divider-img">
                  <img
                    src="/images/web-development/web_development_03_1.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Webdevelopment;