import React, { Component } from "react";

import BackgroundImage from "../backgroundImage/BackgroundImage";
import { Typography, Grid } from "@material-ui/core";
import zIndex from "@material-ui/core/styles/zIndex";

export default class MobileApp extends Component {
  componentDidMount() {}
  render() {
    return (
      <div>
        <BackgroundImage style={"MobileApp-img"} />
        <div data-aos="fade-up" className="web-banner">
          <div>
            <h2>
              <strong>MOBILE APP DEVELOPMENT</strong>
            </h2>
          </div>
        </div>
        <div data-aos="fade-up" className="testimonial-more">
          <div className="testimonial-mores">
            <h4>
              <i className="fa fa-quote-left" aria-hidden="true" />
              MILLION OF APPS ARE AVAILABLE ON THE VARIOUS MOBILE APPLICATION
              STORES THAT FACILITATE THE USER WITH RESPONSIVE, ROBUST, AND
              UPDATED FEATURES.
              <i className="fa fa-quote-right" aria-hidden="true" />
            </h4>
          </div>
        </div>
        <Grid
          container
          className="app-grid"
          style={{ marginBottom: "30px" }}
          justify="center"
          alignItems="center"
          data-aos="fade-up"
        >
          <Grid
            item
            className="d-flex justify-content-center"
            md={6}
            lg={6}
            xl={8}
          >
            <div style={{ width: "90%", margin: "auto" }}>
              <h3 className="App-Heading1">
                “Target your audience through a platform where ready or
                customized applications are marking positions and facilitating
                users with salient features to run in their palm.”
              </h3>
              <Typography className="App-text1" variant="p">
                Mobile applications are gaining undeniable popularity among
                cellular users. These applications could be ready or customized
                that may help companies to facilitate the user with their
                business model. Such applications are running on different
                dedicated operating systems and take developer strategy to
                implement the required features. After the development of these
                applications, such apps could be updated that will provide the
                updated features to its user. These applications can send &
                receive notifications that help the user to detect the recent
                update. With the help of APIs integration in the mobile
                application, this platform can work fast with fewer resources
                used. Introduce your mobile app and get users on board that will
                raise the company standards and enhanced revenue generation.
                Mobile applications range from Entertainment to Education,
                Businesses to NEWS, Food to Fashion, Social Media to MainStream
                Media, etc.
              </Typography>
            </div>
          </Grid>
          <Grid
            className="d-flex justify-content-center App-img1"
            item
            md={6}
            lg={5}
            xl={4}
          >
            <img
              className="animated app-img1"
              src="../images/Mobile-app/mobile-img.png"
              alt=""
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ marginBottom: "30px" }}
          justify="center"
          alignItems="center"
          data-aos="fade-up"
        >
          <Grid
            className="d-flex justify-content-center App-img1"
            item
            md={6}
            lg={5}
            xl={4}
          >
            <img
              className="animated app-img2"
              src="../images/Mobile-app/mobile-img-6.png"
              alt=""
            />
          </Grid>
          <Grid
            item
            className="d-flex justify-content-center"
            md={6}
            lg={6}
            xl={8}
          >
            <div style={{ width: "90%", margin: "auto" }}>
              <h3 className="App-Heading1">
                “Enjoy the in-app built features like push notifications, news,
                APIs integration, call to action buttons, and much more with
                highly secured creative design.”
              </h3>
              <Typography className="App-text1" variant="p">
                Mobile applications give you the freedom of implementing fewer
                features at the start but to retain users figure an update is
                necessary with time. This gradual update could be a new feature,
                enhanced content, more call-to-action buttons, app NEWS,
                third-party integration, and much more. Using such features
                mobile applications can go more interactive keeping users
                onboard. These features can be derived from the user’s feedback
                on the store and its implementation will enhance the trust of
                the user. An application becomes trustworthy with its increasing
                number of users and its appearance ranking over other apps.
                Updated content and keyword usage are important to such apps
                that may help in searching for the right product. API
                integration and app encryption make it much secure. These
                salient features can be added to a new application or an
                existing application can be modified according to the
                requirements but it needs Best Artists to develop an Elite Art.
              </Typography>
            </div>
          </Grid>
        </Grid>
        <div data-aos="fade-up" className="testimonial-more">
          <div className="testimonial-mores">
            <h4>
              <i className="fa fa-quote-left" aria-hidden="true" />
              “Smart IT Tec ensures quality and reliability while developing any
              application that will engage the user while depicting the quality
              standards of your business.”
              <i className="fa fa-quote-right" aria-hidden="true" />
            </h4>
          </div>
        </div>
        <div data-aos="fade-up" className="developApp">
          <Typography data-aos="fade-up-right" variant="h4">
            SMART IT TEC EXPERTS ARE EFFICIENT IN DEVELOPING DIFFERENT MOBILE
            APPS ACCORDING TO NATURE AND SCALE OF THE BUSINESS PROVIDING FAST
            PERFORMANCE, USER-FRIENDLY EXPERIENCE & SECURE ENVIRONMENT.
          </Typography>
          <Typography data-aos="fade-up-left" variant="h5">
            Smart IT Tec experts follow the basic principle and modern
            techniques to develop mobile applications. Smart IT Tec provides you
            mobile applications for both Android and iOS with fast performance
            and enhanced security. Experts at Smart IT Tec understand the
            importance of your business model and the scale of your business. We
            provide you with an expert opinion and the technology that may
            fulfill your business needs and raise the standards of your
            business. After the discussions and understanding of the business
            model, Smart IT Tec experts prepare mockups for you that may help
            you visualize the final product. Approved mockups are then
            implemented to develop the product. Smart IT Tec emphasis on the
            better performance of the application in a secured environment where
            user-experience is kept prior. Mobile applications can be developed
            from scratch or existing applications can be modified that will
            retain the user’s figure.
          </Typography>
        </div>
        {/* <------------------Native App Content--------------------> */}
        <div data-aos="fade-up" className="NativeApp">
          <Typography data-aos="fade-up" variant="h4">
            <i className="fa fa-quote-left" aria-hidden="true" />
            GET YOUR APPLICATION DEVELOPED ON DIFFERENT OS WITH DIFFERENT
            APPLICATION TYPES THAT ARE HIGHLY BASED ON FAST, SECURE, RESPONSIVE
            DESIGN, & SEARCH ENGINE OPTIMIZED SOLUTIONS.
            <i className="fa fa-quote-right" aria-hidden="true" />
          </Typography>
          <Typography data-aos="fade-up" variant="h5">
            Get your offline or online <b> “Native App” </b>on Android or iOS
            devices with efficiently fast and secure performance. Due to its
            presence in the store, users’ positive feedback will push this
            application to rise. These apps can engage a large audience that
            will grow your business stats globally. These apps are highly
            interactive from the user experience point of view.
          </Typography>
          <Grid
            container
            className="nativeItems grid"
            justify="center"
            alignItems="center"
          >
            <Grid item md={7} xl={8}>
              <div className="d-flex justify-content-center align-items-center">
                <div style={{ overflowX: "hidden" }}>
                  <span data-aos="fade-right">
                    <i class="fa fa-check"></i>
                    Built-in mobile feature providing unique functionalities to
                    the user from Smartphone Environment.
                  </span>
                  <span data-aos="fade-right">
                    <i className="fa fa-check" aria-hidden="true" />
                    Tightly coupled Device built-in features make it efficient.
                  </span>
                  <span data-aos="fade-right">
                    <i class="fa fa-check"></i> Native Apps operates in the
                    standalone mode.
                  </span>
                  <span data-aos="fade-right">
                    <i class="fa fa-check"></i>
                    Native Apps can push relevant product recommendations using
                    collected user profiles.
                  </span>
                  <span data-aos="fade-right">
                    <i class="fa fa-check"></i>
                    Native Apps performance is highly scalable that does not
                    make users wait for the responses.
                  </span>
                  <span data-aos="fade-right">
                    <i class="fa fa-check"></i>
                    Third party integrations in the Native Apps helps to
                    optimize the performance and use of data.
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item md={5} xl={4}>
              <div className="d-flex justify-content-center align-items-center">
                <img
                  data-aos="fade-left"
                  style={{ width: "50%" }}
                  src="../images/Mobile-app/mobile-img-5.png"
                  alt="nativeApp"
                />
              </div>
            </Grid>
          </Grid>
        </div>
        {/* <--------------------------------------> */}
        {/* <------------------Web App Content--------------------> */}

        <div data-aos="fade-up" className="NativeApp NativeAppBG">
          <Typography data-aos="fade-up" variant="h4">
            <i className="fa fa-quote-left" aria-hidden="true" />
            SMART IT TEC BRINGS YOU THE BEST APPLICATIONS ON CROSS-BROWSERS WITH
            DIFFERENT TYPES OF APPS. PROVIDE YOUR USER THE BEST EXPERIENCE USING
            THE EASILY ACCESSIBLE MOBILE APP FEATURES AND INCREASE YOUR VISITORS
            AND SALES.
            <i className="fa fa-quote-right" aria-hidden="true" />
          </Typography>
          <Typography data-aos="fade-up" variant="h5">
            Smart IT Tec developers are experts at <b> “Web App” </b>
            development.These apps run on remote servers that store the data and
            help applications perform operations faster. Web Apps are made
            responsive to every device that may use its services. A single
            progressive app can run on different devices, So there is no need to
            build apps for different platforms.
          </Typography>
          <Grid
            container
            className="nativeItems"
            justify="center"
            alignItems="center"
          >
            <Grid item md={5} xl={4}>
              <div
                data-aos="fade-right"
                className="d-flex justify-content-center align-items-center"
              >
                <img
                  style={{ width: "50%" }}
                  src="../images/Mobile-app/mobile-img-3.png"
                  alt="webApp"
                />
              </div>
            </Grid>
            <Grid item md={7} xl={8}>
              <div className="d-flex justify-content-center align-items-center">
                <div style={{ overflowX: "hidden" }}>
                  <span data-aos="fade-left">
                    <i class="fa fa-check"></i>
                    Web Apps run on different browsers i.e. Google Chrome, UC
                    Browser, Opera Browser, Firefox, etc.
                  </span>
                  <span data-aos="fade-left">
                    <i className="fa fa-check" aria-hidden="true" />
                    Web Apps runs online, so it costs low memory storage and can
                    run instantly as the network connects.
                  </span>
                  <span data-aos="fade-left">
                    <i class="fa fa-check"></i> Web Apps can be bookmarked to
                    frequently use the application.
                  </span>
                  <span data-aos="fade-left">
                    <i class="fa fa-check"></i>
                    Web Apps need not to be updated by the user, An updated
                    version of the application can be used instantly.
                  </span>
                  <span data-aos="fade-left">
                    <i class="fa fa-check"></i>
                    Web Apps with bigger scope can run efficiently on the web
                    browser and reduce the installation friction.
                  </span>
                  <span data-aos="fade-left">
                    <i class="fa fa-check"></i>
                    Third party integrations in the Native Apps helps to
                    optimize the performance and use of data.
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* <--------------------------------------> */}
        {/* <------------------Hybrid App Content--------------------> */}
        <div data-aos="fade-up" className="NativeApp">
          <Typography data-aos="fade-up" variant="h4">
            <i className="fa fa-quote-left" aria-hidden="true" />
            EQUIP YOUR MOBILE APP WITH FAST CHECKOUTS AND DATA EXCHANGE SECURELY
            WITH SMART IT TEC EXPERT’S CONSULTATION AND DEVELOPMENT EXPERTISE
            <i className="fa fa-quote-right" aria-hidden="true" />
          </Typography>
          <Typography data-aos="fade-up" variant="h5">
            Smart IT Tec offers the <b>“Hybrid Apps”</b>, blend of Native Apps
            and Web Apps. Web Technologies are used as a core (HTML, CSS, and
            JavaScript) that is enclosed in a shell of a Native App. Hybrid Apps
            uses its embedded operating system that is invisible to the user and
            uses the different function calls on different Operating Systems.
          </Typography>
          <Grid
            container
            className="nativeItems grid"
            justify="center"
            alignItems="center"
          >
            <Grid item md={7} xl={8}>
              <div className="d-flex justify-content-center align-items-center">
                <div style={{ overflowX: "hidden" }}>
                  <span data-aos="fade-right">
                    <i class="fa fa-check"></i>
                    Unified development of Hybrid Apps that allow individual
                    code bases for a single version.
                  </span>
                  <span data-aos="fade-right">
                    <i className="fa fa-check" aria-hidden="true" />
                    Unified development saves development and maintenance
                    expenses.
                  </span>
                  <span data-aos="fade-right">
                    <i class="fa fa-check"></i> Experience Native App feels and
                    features that allows device features usage.
                  </span>
                  <span data-aos="fade-right">
                    <i class="fa fa-check"></i>
                    Hybrid Apps offer offline support for different applications
                    that help users when network connection is lost.
                  </span>
                  <span data-aos="fade-right">
                    <i class="fa fa-check"></i>
                    Hybrid Apps also offer Hassle-free integration with other
                    apps that facilitate users with frictionless experience in
                    device hardware usage.
                  </span>
                  <span data-aos="fade-right">
                    <i class="fa fa-check"></i>
                    Hybrid Apps performance measurement is excellent that loads
                    the application quickly.
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item md={5} xl={4}>
              <div className="d-flex justify-content-center align-items-center">
                <img
                  data-aos="fade-left"
                  style={{ width: "70%", margin: "auto" }}
                  src="../images/Mobile-app/mobile-img-4.png"
                  alt="nativeApp"
                />
              </div>
            </Grid>
          </Grid>
        </div>
        {/* <--------------------------------------> */}
        <div data-aos="fade-up" className="testimonial-more">
          <div className="testimonial-mores">
            <h4>
              <i className="fa fa-quote-left" aria-hidden="true" />
              Fast, reliable, efficient, secured, good user-experience,
              responsive, and engine optimized mobile application is the goal of
              Smart IT Tec in affordable and market competitive charges.
              <i className="fa fa-quote-right" aria-hidden="true" />
            </h4>
          </div>
        </div>
        <div data-aos="fade-up" className="appEndTExt">
          <div className="appEndDiv">
            <h4 className="text-center">
              <sup>
                <i className="fa fa-quote-left" aria-hidden="true" />
              </sup>
              Smart IT Tec is the best company of its nature that sets goals to
              achieve at the start of product development to give quality
              standards to its clients. Smart IT Tec experts hold meetings and
              discussions to understand the application requirement and business
              model to start work upon. We create an environment where our
              developers generate code for your product based on modern
              techniques and the latest tools. Our experts are good at
              integrating third-party services in an application along with the
              arranged database. To achieve the industry and client set goals,
              we perform testing on each module to reduce the risk factor.
              <sup>
                <i className="fa fa-quote-right" aria-hidden="true" />
              </sup>
            </h4>

            <br />
            <Typography variant="h4" className="text-center">
              <sup>
                <i className="fa fa-quote-left" aria-hidden="true" />
              </sup>
              Smart IT Tec experts can also modify your existing application. We
              use minimum resources and less time to develop a valuable product
              for our customers that will speak of our success. For the market
              penetration and positive ratings, an expert’s team should be your
              choice and Smart IT Tec is the name of trust in the industry. Join
              Hands with Smart IT Tec and uplift the standards.
              <sup>
                <i className="fa fa-quote-right" aria-hidden="true" />
              </sup>
            </Typography>
            <div className="box">
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
            </div>
          </div>
        </div>
        {/* <div className="mobile-about">
            <p>
              MILLION OF APPS ARE AVAILABLE ON THE VARIOUS MOBILE APPLICATION
              STORES THAT FACILITATE THE USER WITH RESPONSIVE, ROBUST, AND
              UPDATED FEATURES.
            </p>
          </div>
          <div className="hero-left">
            <h3>
              Target your audience through a platform where ready or customize
              applications are marking positions and facilitating users with
              salient features to run in their palm.
            </h3>

            <div className="react">
              <p>
                Mobile applications are gaining undeniable popularity among
                cellular users. These applications could be ready or customized
                that may help companies to facilitate the user with their
                business model. Such applications are running on different
                dedicated operating systems and take developer strategy to
                implement the required features. After the development of these
                applications, such apps could be updated that will provide the
                updated features to its user. These applications can send &
                receive notifications that help the user to detect the recent
                update. With the help of APIs integration in the mobile
                application, this platform can work fast with fewer resources
                used. Introduce your mobile app and get users on board that will
                raise the company standards and enhanced revenue generation.
                Mobile applications range from Entertainment to Education,
                Businesses to NEWS, Food to Fashion, Social Media to MainStream
                Media, etc.
              </p>
              <div
                className="web-img"
              >
                <img
                  src="/images/Mobile-app/mobile-img.png"
                  className="img-fluid animated"
                  alt=""
                />
              </div>
            </div>
          </div>
          
          <div id="wrapper">

            <div id="content">
              <h2>Apple products</h2>
              <ul id="bar">
                <li id="iphone">
                  <div class="top">
                    <img src="images/iphone.png" alt="iPhone" />
                  </div>
                  <div class="bottom">
                    <div class="infobox">
                      <h3>iPhone</h3>
                      <p>80,1</p>
                    </div>
                  </div>
                </li>
                <li id="macbook">
                  <div class="top">
                    <img src="images/macbook.png" alt="MacBook" />
                  </div>
                  <div class="bottom">
                    <div class="infobox">
                      <h3>MacBook</h3>
                      <p>102,6</p>
                    </div>
                  </div>
                </li>
                <li id="ipod">
                  <div class="top">
                    <img src="images/ipod.png" alt="iPod" />
                  </div>
                  <div class="bottom">
                    <div class="infobox">
                      <h3>iPod</h3>
                      <p>198,4</p>
                    </div>
                  </div>
                </li>
                <li id="cinema">
                  <div class="top">
                    <img src="images/cinema.png" alt="Cinema Display" />
                  </div>
                  <div class="bottom">
                    <div class="infobox">
                      <h3>Cinema&nbsp;Display</h3>
                      <p>38,2</p>
                    </div>
                  </div>
                </li>
                <li id="macmini">
                  <div class="top">
                    <img src="images/macmini.png" alt="Mac Mini" />
                  </div>
                  <div class="bottom">
                    <div class="infobox">
                      <h3>Mac&nbsp;Mini</h3>
                      <p>55,6</p>
                    </div>
                  </div>
                </li>
              </ul>
              
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}
