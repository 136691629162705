import React, { Component } from 'react';

import BackgroundImage from '../backgroundImage/BackgroundImage';

export default class Sms extends Component {
	componentDidMount() {}
	render() {
		return (
		  <div className="SmsApp">
		   <BackgroundImage style={'Sms-img'} />
			<div>
				<div>
					<img src="images/sms/ComingSoon.jpg" alt=""/>
				</div>
			</div>
		  </div>
		);
	}
}
