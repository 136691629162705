import React, { useEffect } from "react";

import Aos from 'aos';

import "aos/dist/aos.css";

const languSingle = (props) => {

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, []);
    return (
        <React.Fragment >
            <div 
                 _ngcontent-my-app-c12="" className="flex-row">
                <div
                
                    _ngcontent-my-app-c12=""
                    className="technologies"
                    id="main"
                    data-aos="zoom-in-up"
                    data-aos-easing="linear"
                    data-aos-duration="1500"
                >
                    <div className="languSingle">
                        <h1>{props.product.title}</h1>
                        <p>{props.product.description}</p>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )
}


export default languSingle;
