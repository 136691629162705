// // ########## Import Dependencies Here ##########

import React from 'react';
import Blog from './Whatshappen/Blog';
import JuniorSchool from './JuniorSchool';
import LanguList from         './languList'; 
import {languApi} from './languApi';



class Promo extends React.Component {
  state={
    products:languApi,
    productCopy:[]
  }

  // filtering btn function 
  handleBtn=(e)=>{
    console.log(e.target.value);
    let productCopy;

    if(e.target.value=== "All"){
      productCopy=this.state.products;
    }else{
      productCopy=this.state.products.filter(item=>item.cat===e.target.value)
    }
    this.setState({
      productCopy:productCopy
    })
  }

  componentDidMount(){

  }
  render() {
    return (
      <div className="home">
           <JuniorSchool />
           <Blog />
           <LanguList products={this.state.productCopy} handleBtn={
             this.handleBtn
           }/>
      </div>
    )
  };
}
export default Promo;