import React, { Component } from "react";

import BackgroundImage from "../backgroundImage/BackgroundImage";
import { Grid } from "@material-ui/core";

export default class Smm extends Component {
  componentDidMount() {}
  render() {
    return (
      <div className="SmmApp">
        <BackgroundImage style={"Smm-img"} />
        <br />
        <br />
        <div className="smm-banner">
          <h2>
            <strong>Social Media Marketing</strong>
          </h2>
        </div>
        <Grid
          container
          className="Smm-Grid1"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item md={5} lg={5}>
            <div className="smm-img d-flex justify-content-center align-items-center">
              <img
                style={{ width: "80%", margin: "auto" }}
                src="/images/smm/SocialMedia3.png"
                className="animated"
                alt="SocialMedia"
              />
            </div>
          </Grid>
          <Grid item md={7} lg={7}>
            <div className="smm_grid_text">
              <div className="smm_left">
                <p>
                  The world is turning into a global village because of social
                  media websites. An average person spends 144minutes a day on
                  social media and confronts advertisements of his interest. On
                  social media platforms, companies try to approach targeted
                  audiences through marketing by identifying their interest.
                  Smart IT Tec plans the strategy for marketing and
                  intelligently selects your audience that builds up their
                  interest in your brand.
                </p>
              </div>
              <div className="smm_left_1">
                <p>
                  Social media has a great influence on our lives and most
                  brands advertise themselves on these platforms. Providing a
                  facility to cover the targeted audiences, Smart IT Tec is
                  committed to helping the businesses. Social media Integrated
                  websites have yielded more results than conventional website
                  platforms. Social Media Marketing helps you grow the business
                  stats by adding new customers. Social Media Marketing adds up
                  organic audiences bringing comfortable experiences at their
                  home.
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
        <div data-aos="fade-up" className="testimonial-more">
          <div className="testimonial-mores">
            <h4>
              <i className="fa fa-quote-left" aria-hidden="true" />
              Target your audience through Social Media Accounts strategically
              and intelligently and there is no good stop other than Smart IT
              Tec.
              <i className="fa fa-quote-right" aria-hidden="true" />
            </h4>
          </div>
        </div>

        <div className="container smm-h2">
          <h2>
            <i className="fa fa-quote-left" aria-hidden="true"></i>Speak with
            your audience directly through online social media platforms having
            huge audiences like Facebook, Twitter, Pinterest, Instagram,
            LinkedIn, and YouTube.
            <i className="fa fa-quote-right" aria-hidden="true"></i>
          </h2>
          <Grid container className="container smm-grid2">
            <Grid item md={7} lg={7}>
              <div className="smm-text">
                <ul>
                  <li>
                    <p>
                      Achieve your branding and marketing goals by uploading
                      different content like texts, images, videos, and
                      animation using Smart IT Tec plans and strategies. Smart
                      IT Tec will improve your business social presence, which
                      will also enhance the visitor’s figure on the web platform
                      as well making your content viral. Social media marketing
                      also engages your audience by raising awareness that how
                      our products or items can meet the user’s criteria.
                      Satisfied client’s feedback on social media also plays a
                      part to build trust in the community. Most clients are
                      interacting with brands through social media using chat
                      features that makes communication fast.
                      <br />
                      <br />
                      Building a new business page over social media that can
                      capture the member’s interest isn’t an easy job, Smart IT
                      Tec will announce your business that will cover a huge
                      audience in an inexpensive method. The call to action
                      function on these platforms brings the audience review
                      that appreciates the customer’s engagement with the
                      company. With the change in communication channels, the
                      audience is turning to social media, So Social media is
                      the right choice for an announcement.
                    </p>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item md={5} lg={5}>
              <div className="d-flex justify-content-center smm-img2">
                <img
                  style={{ margin: "auto" }}
                  src="../images/smm/SocialMedia5.png"
                  className="animated"
                  alt=""
                />
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="smm-h3">
          {/* <div classN ame="smm-img3"></div> */}
          <Grid container justify="center" alignItems="center">
            <Grid item md={5}>
              <h3>
                What we do as
                <br />
                <span>Social Media Marketer</span>
              </h3>
              <ul className="plus">
                <li>Brand Awareness</li>
                <li>Increase your website traffic through social media</li>
                <li>Improved audience insights</li>
                <li>Become an influencer in your industry</li>
                <li>Increase traffic</li>
                <li>Enhance SEO rankings</li>
                <li>Cost-Effective</li>
                <li>Learn about your audience</li>
                <li>Target audiences more effectively</li>
                <li>Find new customers &amp; expand your audience</li>
                <li>Instant Feedback</li>
                <li>Generate leads</li>
              </ul>
            </Grid>
            <Grid style={{overflow:"hidden"}} item md={7}>
              <div
                className="smm-img3"
                data-aos="fade-left"
                data-aos-delay="200"
              >
                <img
                  src="../images/smm/SocialMedia2.png"
                  className="animated"
                  alt=""
                />
              </div>
            </Grid>
          </Grid>
        </div>

        <Grid
          container
          justify="center"
          alignItems="center"
          className="container  smm-company-required"
        >
          <Grid item md={4} lg={5}>
            <div className="smm_company_img">
              <img src="/images/smm/SocialMedia1.png" alt="" />
            </div>
          </Grid>
          <Grid item md={8} lg={7} className="smm_target_p">
            <h3>
              <i className="fa fa-quote-left" aria-hidden="true"></i>
              Set the target audience based on demographics like age,culture,
              gender, etc to achieve the social media marketing goals.
              <i className="fa fa-quote-right" aria-hidden="true"></i>
            </h3>
            <div className="smm_company_img2">
              <img src="/images/smm/SocialMedia1.png" alt="" />
            </div>
            <p>
              Data collected by social media platforms help the companies
              targeting an interested audience based on the demographics.
              Finding Interests of consumers and investing money to market your
              business digitally is the “new” right way to grow the business.
              Smart IT Tec experts advise, keeping your business marketing goals
              in mind can raise revenue generation. Using the region settings
              and scheduling feature to market business over social media
              followed by promos and discounts lead more visitors to your web
              platform.
              <br />
              <br />
              Social Media Marketing helps you promote an event, publishing and
              promoting books, reserving seats in a restaurant, offering
              discounted vouchers to regular users, announcing new arrivals, and
              much more. Using hashtag features combined with optimized content
              will top rank the product and will enhance your positioning on
              social media. Social Media inexpensive Marketing stats can also
              help you in predicting the success factor.
            </p>
          </Grid>
        </Grid>
        <div className="Third-Party1">
          <div className="Third-Party1-P">
            <Grid container justify="center" alignItems="center">
              <Grid item md={6}>
                <h3>
                  Organic Reach is down, advertisement isn’t hitting the
                  audience, website clicks are down: Don’t worry Smart IT Tec
                  custom Social Media Marketing plans will help you.
                </h3>
                <p>
                  Set the marketing goals and choose Smart IT Tec promotion
                  plans to hit the achievers bell. Social Media Marketing using
                  Smart IT Tec custom plans are inexpensive that initiates with
                  our expert’s consultancy, to enhanced customer reach to your
                  brand. Smart IT Tec first analyzes your business and existing
                  strategies. If the company has just initiated, we will create
                  pages for you or will modify the existing one. Your business
                  goals and trends matter the most. So let’s get started with
                  Smart IT Tec plans and target the customer needs. Adopt the
                  modern means of advertisements and join the digital world of
                  marketing. Skyrocket your earnings by converting visitors into
                  customers and provide the quality of your products but to
                  fulfill these, Smart IT Tec inexpensive plans, experts
                  opinion, and strategic planning should be your first choice.
                </p>
              </Grid>
              <Grid item md={6}>
                <div className="Third-Party1-img">
                  <img
                    src="../images/smm/SocialMedia6.png"
                    className="animated"
                    alt=""
                  />
                </div>
              </Grid>
            </Grid>
            <div className="box">
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
              <div className="box">.</div>
            </div>
          </div>
        </div>
        <section className="divider">
          <div className="container wow fadeInUp animated">
            <div className="row">
              <div className="col-md-8 col-md-offset-2">
                <div className="divider-para">
                  <p>
                    <i className="fa fa-quote-left" />
                    User-friendly website design retains customers' tour time
                    that gives the whole information to the client easily.
                    <i className="fa fa-quote-right" />
                  </p>
                </div>
                <div className="divider-img">
                  <img
                    src="/images/web-development/web_development_03_1.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
