export const FILL_ME = 'FILL_ME';

// ######## Calender Constants ##########
export const ARROW_LEFT = 'ARROW_LEFT';
export const ARROW_RIGHT = 'ARROW_RIGHT';
export const CALENDER_DATA = 'CALENDER_DATA';
export const INITIAL_BLOG_VIEW = 'INITIAL_BLOG_VIEW';
export const NEXT_VIEW_SAGA = 'NEXT_VIEW_SAGA';
export const PREVIOUS_VIEW_SAGA = 'PREVIOUS_VIEW_SAGA';
export const INITIAL_VIEW = 'INITIAL_VIEW';
export const NEXT_VIEW = 'NEXT_VIEW';
export const PREVIOUS_VIEW = 'PREVIOUS_VIEW';
export const NEXT_RESP_VIEW = 'NEXT_RESP_VIEW';
export const PREVIOUS_RESP_VIEW = 'PREVIOUS_RESP_VIEW';
export const GET_USERNAME = 'GET_USERNAME';
export const GET_PASSWORD = 'GET_PASSWORD';
export const LOGIN = 'LOGIN';
export const GET_ACHIEVERS_DATA = 'GET_ACHIEVERS_DATA';
export const GET_ACHIEVERS_DATA_SUCCESS = 'GET_ACHIEVERS_DATA_SUCCESS';
export const GET_ACHIEVERS_DATA_ATTEMPT = 'GET_ACHIEVERS_DATA_ATTEMPT';
export const GET_ACHIEVERS_DATA_FAIL = 'GET_ACHIEVERS_DATA_FAIL';
export const GET_CALENDER_DATA = 'GET_CALENDER_DATA';
export const GET_CALENDER_DATA_SUCCESS = 'GET_CALENDER_DATA_SUCCESS';
export const GET_CALENDER_DATA_ATTEMPT = 'GET_CALENDER_DATA_ATTEMPT';
export const GET_CALENDER_DATA_FAIL = 'GET_CALENDER_DATA_FAIL';

export const HEADER_DATA = 'HEADER_DATA';
