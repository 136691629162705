const data = [
	{
		id: Math.random(),
		img: './ServiceImages/WebDevelopment.jpg',
		title: 'Web develepmont',
		description:
			'Introduce your business on websites or modify your existing websites that will assure highly interactive, responsive and fast performing features.'
	},
	{
		id: Math.random(),
		img: './ServiceImages/MobileAppDevelopment.jpg',
		title: 'Mobile App Development',
		description:
			'Smart IT Tec offers developing a new application or modifying the existing one that will update your business information in the palm of the users either online or offline.'
	},
	{
		id: Math.random(),
		img: './ServiceImages/EcommerceWebsite.jpg',
		title: 'E-commerce Website',
		description:
			'Discuss your E-Commerce store business idea with our Experts and get the fast-processing checkout system and relevant product recommendations that will generate high revenue.'
	},
	{
		id: Math.random(),
		img: './ServiceImages/LogoDesign.jpg',
		title: 'Logo Design',
		description:
			'Aim for a new identity or an improved one, Smart IT Tec should be your first choice whose expert will leave an excellent impression over the visitors.'
	},
	{
		id: Math.random(),
		img: './ServiceImages/WebDesign.jpg',
		title: 'UI/UX',
		description:
			'Highly interactive, responsive, user friendly, and easy to navigate website  and app designs are the Smart IT Tec expertise that we build over years.'
	},
	{
		id: Math.random(),
		img: './ServiceImages/SEOOptimization.jpg',
		title: 'Search Engine Optimization',
		description:
			'Increase the quality and quantity of your audience through organic search results and rank your website through the relevant keywords suggested and implemented by Smart IT Tec.'
	},
	
	{
		id: Math.random(),
		img: './ServiceImages/SocialMediaMarketing.jpg',
		title: 'Social Media Marketing',
		description:
			'Smart IT Tec brings you affordable, efficient, and effective promotion packages that will help you grow strategically and will target the right audience to promote your message.'
	},
		
];
export default data;
